import { ContentLayout } from '@/components/Layout';
import { Notification, useNotifications } from '../../api/notifications/getAllNotifications';
import { Button, Spinner } from '@/components/Elements';
import './notify.css';
import { useEffect, useState } from 'react';
import NotifyAll from './NotifyAll';
import { deleteNotification } from '../../api/notifications/deleteNotification';
import { useNotificationStore } from '@/stores/notifications';
import { readNotifications } from '../../api/notifications/readNotifications';
import { useNavigate } from 'react-router-dom';
import { timeFromNow } from '@/utils/format';
import { usePagination } from '@/hooks/usePagination';
import { Pagination } from '@/components/pagination/Pagination';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

const AllNotification = () => {
  const { page, changePage } = usePagination();
  const { data: notification, isLoading, refetch } = useNotifications({ page: page });

  const { addNotification } = useNotificationStore();
  const [count, setCount] = useState<number>(10);
  const [selected, setSelected] = useState<string[]>([]);

  const navigate = useNavigate();
  const handleSelect = (id: string) => {
    let curSelected = [...selected];
    if (curSelected.includes(id)) {
      curSelected = curSelected.filter((i) => i !== id);
    } else {
      curSelected.push(id);
    }
    setSelected(curSelected);
  };

  const selectAll = () => {
    if (selected.length === notification?.data?.length) {
      setSelected([]);
    } else {
      const currentSelected = notification?.data?.map((i) => i._id);
      if (currentSelected) {
        setSelected(currentSelected);
      }
    }
  };

  const handleDelete = async () => {
    const payload = {
      ids: selected,
    };
    await deleteNotification(payload);
    addNotification({
      type: 'success',
      title: 'Notification deleted successfuly',
    });
    setSelected([]);
    refetch();
    setCount(count - selected.length);
  };

  const handleRead = async () => {
    const payload = {
      ids: selected,
    };
    await readNotifications(payload);
    addNotification({
      type: 'success',
      title: 'Notification Read successfuly',
    });
    setSelected([]);
    refetch();
  };

  const handleAdd = () => {
    setCount(count + 5);
  };
  const handleSub = () => {
    setCount(count - 5);
  };

  const navs: {
    [key: string]: string;
  } = {
    query: 'help-support',
    user_created: 'view-user',
    driver_created: 'view-driver',
  };

  useEffect(() => {
    console.log(page);
    setSelected([])
    refetch();
  }, [page]);


  console.log(selected.length);
  console.log(notification?.data?.length);

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <NewSpinner />
        </div>
      ) : (
        <ContentLayout title="Notifications">
          <div className="bg-white rounded-lg p-4 shadow">
            <div className="heading">
              <div className="d-flex justify-content-end gap-2 mb-3 toper">
                {selected.length > 0 ? (
                  <>
                    <button onClick={handleDelete}>
                      Delete{' '}
                      <span className="smallTert">
                        {selected.length > 0 ? selected.length : null}
                      </span>
                    </button>
                    <button onClick={handleRead}>
                      Read{' '}
                      <span className="smallTert">
                        {selected.length > 0 ? selected.length : null}
                      </span>
                    </button>
                  </>
                ) : null}
                {notification?.data?.length !== 0 && (
                  <button onClick={selectAll}>
                    {selected.length === notification?.data?.length ? 'Unselect All' : 'Select All'}
                  </button>
                )}
              </div>
            </div>

            <div className="notifies">
              {notification?.data?.length === 0 && (
                <div className="d-flex justify-content-center my-5">
                  <h4 style={{ color: '#696969' }}> No new notification </h4>
                </div>
              )}
              <ul>
                {notification?.data?.slice(0, count).map((item) => (
                  <>
                    <div className="row w-100 mb-2 d-flex f-13 pt-2 mt-4">
                      <div className="col-md-2 d-flex justify-content-start">
                        <input
                          type="checkbox"
                          style={{ width: '20px', height: '20px' }}
                          checked={selected.includes(item?._id)}
                          onChange={() => {
                            handleSelect(item?._id);
                          }}
                        />
                        {!item.read && <span style={{ color: 'red', marginLeft: '5px' }}>•</span>}
                      </div>
                      <div className="col-md-10">
                        <li key={item?._id}>
                          <button
                            className="w-100 d-flex justify-content-between"
                            onClick={async () => {
                              await readNotifications({ ids: item?._id });
                              refetch();
                              navigate(
                                item.type === 'query'
                                  ? `/${navs[item.type]}`
                                  : `/${navs[item.type]}/${item.user}`
                              );
                            }}
                          >
                            <span className={item.read ? 'font-normal' : 'font-medium'}>
                              {item?.description}
                            </span>
                            <span className="">{timeFromNow(item.createdAt)} ago</span>
                          </button>
                        </li>
                      </div>
                    </div>
                  </>
                ))}
              </ul>
            </div>
            {/* {notification?.data?.length !== 0 && (
                            <div className="mt-5 d-flex flex-row gap-2">
                                {count <= 10 ? (
                                    <div>
                                        <Button onClick={() => handleAdd()}>Show More</Button>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            {count <= 10 ? null : (
                                                <Button onClick={() => handleSub()}>Show Less</Button>
                                            )}
                                        </div>
                                        <div>
                                            {notification?.data?.length && count >= notification?.data?.length ? null : (
                                                <Button onClick={() => handleAdd()}>Show More</Button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )} */}
          </div>

          <div>
            <Pagination
              total={notification?.count}
              totalPages={notification?.totalPages}
              page={notification?.page}
              changePage={changePage}
            />
          </div>
        </ContentLayout>
      )}
    </>
  );
};

export default AllNotification;
