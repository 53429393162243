import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { Profile } from '@/features/owner';
import User from '@/features/owner/routes/users/User';
import { AddUser } from '@/features/owner/routes/users/AddUser';
import { EditUser } from '@/features/owner/routes/users/EditUser';
import PersonalInformation from '@/features/owner/routes/settings/PersonalInformation';
import GeneralSetting from '@/features/owner/routes/settings/GeneralSetting';
import Password from '@/features/owner/routes/settings/Password';
import HelpSupport from '@/features/owner/routes/helpAndSupport/HelpSupport';
import ContentManagement from '@/features/owner/routes/contentManagement/ContentManagement';
import ContentPage from '@/features/owner/routes/contentManagement/ContentPage';
import Drivers from '@/features/owner/routes/drivers/Drivers';
import AddDriver from '@/features/owner/routes/drivers/AddDriver';
import { EditDriver } from '@/features/owner/routes/drivers/EditDriver';
import ViewDriver from '@/features/owner/routes/drivers/ViewDriver';
import ViewUser from '@/features/owner/routes/users/ViewUser';
import Faq from '@/features/owner/routes/faq/Faq';
import AddFaq from '@/features/owner/routes/faq/AddFaq';
import { EditFaq } from '@/features/owner/routes/faq/EditFaq';
import AllNotification from '@/features/owner/routes/notification/AllNotification';
import Rides from '@/features/owner/routes/rides/Rides';
import PaymentList from '@/features/owner/routes/paymentList/PaymentList';
import Vehicle from '@/features/owner/routes/vehicle/Vehicle';
import { AddVehicle } from '@/features/owner/routes/vehicle/AddVehicle';
import { EditVehicle } from '@/features/owner/routes/vehicle/EditVehicle';
import { Reports } from '@/features/owner/routes/reports/reports';
import ViewRide from '@/features/owner/routes/rides/ViewRide';
import ViewFaq from '@/features/owner/routes/faq/ViewFaq';
import Sortable from '@/features/owner/routes/vehicle/Sortable';
import Payout from '@/features/owner/routes/payouts/Payout';
import ViewPayout from '@/features/owner/routes/payouts/ViewPayout';

const { Dashboard } = lazyImport(() => import('@/features/owner'), 'Dashboard');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
      { path: '/users', element: <User /> },
      { path: 'add-user', element: <AddUser /> },
      { path: 'profile', element: <Profile /> },
      { path: 'user/edit/:id', element: <EditUser /> },
      { path: '/personal', element: <PersonalInformation /> },
      { path: '/general-settings', element: <GeneralSetting /> },
      { path: '/password', element: <Password /> },
      { path: '/help-support', element: <HelpSupport /> },
      { path: '/content', element: <ContentManagement /> },
      { path: '/edit-content/:id', element: <ContentPage /> },
      { path: '/drivers', element: <Drivers /> },
      { path: '/add-drivers', element: <AddDriver /> },
      { path: '/edit-driver/:id', element: <EditDriver /> },
      { path: '/view-driver/:id', element: <ViewDriver /> },
      { path: '/view-user/:id', element: <ViewUser /> },
      { path: '/view-payout', element: <ViewPayout /> },
      { path: '/faq', element: <Faq /> },
      { path: '/add-faq', element: <AddFaq /> },
      { path: 'edit-faq/:id', element: <EditFaq /> },
      { path: '/all-notifications', element: <AllNotification /> },
      { path: '/rides', element: <Rides /> },
      { path: '/payments', element: <PaymentList /> },
      { path: '/payouts', element: <Payout /> },
      { path: '/vehicle', element: <Vehicle /> },
      { path: '/add-vehicle', element: <AddVehicle /> },
      { path: '/edit-vehicle/:id', element: <EditVehicle /> },
      { path: '/report', element: <Reports /> },
      { path: '/view-ride/:id', element: <ViewRide /> },
      { path: 'view-faq/:id', element: <ViewFaq /> },
      { path: 'sortable', element: <Sortable /> },
    ],
  },
];
