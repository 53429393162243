import { axios } from "@/lib/axios"
import { HelpQuery } from "./getHelpQueries";

type ApiData = {
    status: string;
    _id: string;
    role: string;
    service: string;
    query: string;
    name: string;
    email: string;
    phoneNumber: number;
    createdAt: string;
    updatedAt: string;
    response?: string;
}

export type HelpApiData = {
    data: ApiData
}

export const getSingleQuery = (id: string): Promise<HelpApiData> => {
    return axios.get(`/admin/single-query/${id}`)
};