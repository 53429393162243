import { Button, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import exportIcon from '@/assets/export_icon.png';
import { useRides } from '../../api/rides/getRides';
import { usePagination } from '@/hooks/usePagination';
import { useEffect, useState } from 'react';
import { RideDTO, RideData } from './rideType';
import { upperFirst } from 'lodash';
import moment from 'moment';
import eye from '@/assets/eye.png';
import { ridesCsv } from '../../api/csv/ridesCsv';
import { useNavigate } from 'react-router-dom';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';
import { Menu, MenuItem } from '@mui/material';
const Rides = () => {
  const { page, changePage } = usePagination();
  const navigate = useNavigate();
  const [searchkey, setSearchKey] = useState<string>('');
  const [statuskey, setStatusKey] = useState<string>('');
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading, isFetching, refetch } = useRides({
    page: page,
    search: searchkey,
    status: statuskey,
  });

  const options = [
    {
      label: 'Status',
      value: '',
    },
    {
      label: 'Accepted',
      value: 'accepted',
    },
    {
      label: 'pending',
      value: 'pending',
    },
    {
      label: 'Completed',
      value: 'completed',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
  ];

  const handleSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleStatus = (value: string) => {
    setStatusKey(value);
  };

  const handleExport = async (type: string) => {
    try {
      setExportLoader(true);
      const fileName = await ridesCsv({
        page: page,
        search: searchkey,
        status: statuskey,
        type,
      });
      window.open(`${process.env.REACT_APP_API_URL}/${fileName?.data}`);
      setExportLoader(false);
    } finally {
      setExportLoader(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [searchkey, statuskey]);

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <>
      <ContentLayout title="">
        <div className="container my-4">
          <div className="row">
            <div className="col-md-6">
              <h3 className="font-bold">Rides</h3>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{ paddingRight: '0.75rem' }}
                value={searchkey}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <select
                className="form-select"
                value={statuskey}
                onChange={(e) => handleStatus(e.target.value)}
              >
                {options.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 text-end">
              <Button
                className="px-4"
                variant="outline"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                isLoading={exportLoader}
                startIcon={<img src={exportIcon} width="20" />}
                onClick={handleClick}
              >
                Export
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleExport('all');
                    handleClose();
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExport('filters');
                    handleClose();
                  }}
                >
                  with Filters
                </MenuItem>
              </Menu>
            </div>
          </div>

          {isLoading || isFetching ? (
            <div className="d-flex justify-content-center align-items-center">
              <NewSpinner />
            </div>
          ) : (
            <div className="bg-white rounded mt-3">
              <div className="mt-3">
                <Table<RideData>
                  page={page}
                  changePage={changePage}
                  total={data?.count ?? 1}
                  totalPages={data?.totalPages ?? 1}
                  data={data?.data ?? []}
                  columns={[
                    { title: 'Ride ID', field: 'rideUID' },
                    {
                      title: 'Pickup Location',
                      field: 'pickupLocation',
                      Cell({ entry: { pickupLocation } }) {
                        return (
                          <span>
                            {upperFirst(pickupLocation?.name.slice(0, 10))}
                            {pickupLocation?.name.length > 10 ? '..........' : ''}
                          </span>
                        );
                      },
                    },
                    {
                      title: 'Drop Off Location',
                      field: 'pickupLocation',
                      Cell({ entry: { dropLocation } }) {
                        return (
                          <span>
                            {upperFirst(dropLocation?.name.slice(0, 10))}
                            {dropLocation?.name.length > 10 ? '..........' : ''}
                          </span>
                        );
                      },
                    },
                    { title: 'Driver Name', field: 'driverName' },
                    {
                      title: 'User Name',
                      field: 'userName',
                      Cell({ entry: { userName } }) {
                        return <span>{upperFirst(userName)}</span>;
                      },
                    },
                    {
                      title: 'Payment Mode',
                      field: 'paymentMode',
                      Cell({ entry: { paymentMode } }) {
                        return <span>{paymentMode ? upperFirst(paymentMode) : 'Cash'}</span>;
                      },
                    },
                    {
                      title: 'Date of Ride',
                      field: 'createdAt',
                      Cell({ entry: { createdAt } }) {
                        const date = moment(createdAt).format('DD MMM YYYY');
                        return <span>{date}</span>;
                      },
                    },
                    {
                      title: 'Status',
                      field: 'createdAt',
                      Cell({ entry: { status } }) {
                        const positiveStatuses = ['accepted', 'completed'];
                        const isPositiveStatus = positiveStatuses.includes(status.toLowerCase());

                        const isPendingStatus = status.toLowerCase() === 'pending';

                        let style;
                        if (isPositiveStatus) {
                          style = { color: 'green' };
                        } else if (isPendingStatus) {
                          style = { color: 'orange' };
                        } else {
                          style = { color: 'red' };
                        }

                        return <span style={style}>{upperFirst(status)}</span>;
                      },
                    },
                    {
                      title: 'Action',
                      field: 'id',
                      Cell({ entry: { _id } }) {
                        return (
                          <Button
                            tabIndex={-1}
                            className="icon me-2"
                            variant="outline"
                            title="view"
                            onClick={() => navigate(`/view-ride/${_id}`)}
                          >
                            <img src={eye} alt="" />
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

export default Rides;
