import { Dialog, Menu, Transition } from '@headlessui/react';
import { UserIcon, HomeIcon, MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';

import logo from '@/assets/logo.png';
import DriverLogo from '@/assets/driver_icon.png';
import menu from '@/assets/menu.png';
import user from '@/assets/user.png';
import rides from '@/assets/auto_icon.png';
import payment from '@/assets/payment_icon.png';
import report from '@/assets/report.png';
import settings from '@/assets/setting.png';
import help from '@/assets/help.png'
import { useAuth } from '../../lib/auth';
import './layout.css';
import { MenuNav } from './MenuNav';
import { upperFirst } from 'lodash';
// import bell from '@/assets/Bell.png';
import bell from '@/assets/notification.png'
import profile from '@/assets/placeholder.jpg'
import { useDisclosure } from '@/hooks/useDisclosure';
import { useNotifications } from '@/features/owner/api/notifications/getAllNotifications';
import { Box, Modal, Typography } from '@mui/material';
import { Button, Spinner } from '../Elements';
import Notify from '@/features/owner/routes/notification/Notify';
import arrow from '@/assets/arrow.png';
import { timeFromNow } from '@/utils/format';
import { readNotifications } from '@/features/owner/api/notifications/readNotifications';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: string;
};

const SideNavigation = () => {
  const navigation = [
    { name: 'Dashboard', to: '.', icon: menu },
    { name: 'Drivers', to: './drivers', icon: DriverLogo },
    { name: 'Users', to: '/users', icon: user },
    { name: 'Rides', to: './rides', icon: rides },
    { name: 'Payment', to: './payment', icon: payment },
    { name: 'Report', to: './report', icon: report },
    { name: 'Settings', to: '/settings', icon: settings },
    { name: 'Help & Support', to: './help', icon: help },

  ].filter(
    Boolean
  ) as SideNavigationItem[];
  return (
    <>
      {navigation.map((item, index) => (
        <NavLink
          end={index === 0}
          key={item.name}
          to={item.to}
          className={clsx(
            'text-white hover:text-white dashboard',
            'group menu-items flex items-center px-2 py-2 text-base font-medium rounded-md text-decoration-none'
          )}
        >

          <img src={item.icon} className={clsx(
            'mr-4 flex-shrink-0 h-6 w-6'
          )} />
          {item.name}
        </NavLink>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { user, logout } = useAuth();
  const [modalOpen, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: notification, isLoading, refetch } = useNotifications();

  const navigate = useNavigate();

  const userNavigation = [
    // { name: 'Your Profile', to: './profile' },
    {
      name: 'Sign out',
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 0,
  };

  const unreadNotifications = notification?.data?.filter((i) => !i.read) ?? [];

  const navs: {
    [key: string]: string;
  } = {
    "query": 'help-support',
    "user_created": 'view-user',
    "driver_created": 'view-driver'
  }
  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div className='notification-box d-flex flex-row gap-2'>
            <div className="dropdown">
              <Link
                data-bs-toggle="dropdown"
                aria-expanded="false"
                to=""
                id="dropdownMenuLink"
                className='bg-gray-200 p-2 flex items-center text-sm rounded-full focus:outline-none  dropdown-toggle'
              >
                <img
                  src={bell}
                  alt=""
                  style={{ height: "36px", width: "36px" }}
                />
              </Link>
              {unreadNotifications && unreadNotifications?.length > 0 &&
                <span className='countNotification'>{unreadNotifications?.length}</span>
              }
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <div className="notify-main-title px-4 pt-3 mb-0 f-22 font-medium">Notifications</div>
                </li>
                {unreadNotifications?.length === 0 &&
                  <li
                    className='p-4'
                    style={{ color: '#757575', fontWeight: '400' }}
                  >
                    <p>No new notifications</p>
                  </li>
                }
                {isLoading ? <Spinner /> : unreadNotifications.slice(0, 5).map((notification) => {
                  return (
                    <>
                      <li
                        key={notification?._id}
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          await readNotifications({ ids: [notification?._id] })
                          refetch();
                          navigate(notification.type === 'query' ? `/${navs[notification.type]}` : `/${navs[notification.type]}/${notification.user}`)
                        }
                        }
                      >
                        <div className="notification-wrap">
                          <div className="row px-4">
                            <div className="col-md-8 desc flex-column align-items-left justify-content-start">
                              <h6 className="f-14 mb-1 d-block w-100 text-left" style={{ color: '#757575', fontWeight: '600' }}>
                                <button>
                                  {notification?.title}
                                </button>
                              </h6>
                              <p className="f-12 mb-0 w-100 text-left" style={{ color: '#757575', fontWeight: '400' }}>
                                {notification?.description}
                              </p>
                            </div>
                            <div
                              className="col-md-4 date justify-content-end"
                              style={{ fontWeight: '400' }}
                            >
                              <span className="f-12" style={{ color: '#757575', fontWeight: '400' }}>{timeFromNow(notification?.createdAt)} ago</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  )
                })}
                <div className="text-center">
                  <Button className='notification-btn'
                    onClick={() => navigate('/all-notifications')}
                  >
                    View All Notifications <img className='notify-icon' src={arrow} alt="" />
                  </Button>
                </div>
              </ul >
            </div>


            <div>
              {!user?.data?.image ?
                <Menu.Button className="max-w-xs  bg-gray-200 p-2 flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <UserIcon className="h-8 w-8 rounded-full" />
                </Menu.Button>
                :
                <Menu.Button>
                  <img
                    src={user?.data?.image}
                    className="rounded-full" alt=""
                    style={{ height: "3.2rem", width: "3.2rem" }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = profile;
                    }}
                  />
                </Menu.Button>
              }
            </div>
            <div className="d-flex flex-column">
              <div className='user-name'>
                <span className='font-semibold' style={{ color: "#150035", fontSize: "14px" }}>{upperFirst(user?.data?.name)}</span>
              </div>
              <div className='user-name'>
                <span className='font-medium' style={{ color: "#757575", fontSize: "14px" }}>{upperFirst(user?.data?.role)}</span>
              </div>
            </div>
          </div>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center justify-center flex-shrink-0 px-4" style={{ backgroundColor: "#000000" }}>
            <Logo />
          </div>
          <div className="flex-1 flex flex-col hide-scroll overflow-y-auto sidebaar">
            <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1" style={{ backgroundColor: "#000000" }}>
              {/* <SideNavigation /> */}
              <MenuNav />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center mt-4 text-white" to=".">
      <img className="dashboard-logo" src={logo} alt="Workflow" />
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
      </div>
    </div>
  );
};
