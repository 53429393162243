import { Dialog as UIDialog, Transition } from '@headlessui/react';
import * as React from 'react';
import './dialog.css';
import 'intersection-observer';

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  crossAction?: () => void;
  children: React.ReactNode;
  initialFocus?: React.MutableRefObject<null>;
  footerButton?: React.ReactElement;
  title?: string;
  hideButton?: boolean;
};

export const DialogTitle = UIDialog.Title;

export const DialogDescription = UIDialog.Description;

export const Dialog = ({
  isOpen,
  onClose,
  children,
  initialFocus,
  footerButton,
  title,
  crossAction,
}: DialogProps) => {
  return (
    <>
      <Transition.Root show={isOpen} as={React.Fragment}>
        <UIDialog
          as="div"
          static
          className="fixed main-dialog-bx z-10 inset-0 overflow-y-auto"
          open={isOpen}
          onClose={onClose}
          initialFocus={initialFocus}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <UIDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-top bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div
                  className="dialog-btn"
                  style={{
                    textAlign: 'right',
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                    zIndex: '9',
                  }}
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={crossAction ? crossAction : onClose}
                  >
                    {' '}
                  </button>
                </div>
                {title && (
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" className="f-24">
                      {title}
                    </DialogTitle>
                  </div>
                )}
                <div className="modal-content text-center border-0 pt-4 pb-2">{children}</div>
                {footerButton && (
                  <div className="footerButtons mt-2 sm:mt-2 sm:flex sm:flex-row-reverse justify-content-center text-center">
                    {footerButton}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </UIDialog>
      </Transition.Root>
    </>
  );
};
