import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Driver } from '../../routes/drivers/driverTypes';



export const getDriverProfile = (id: string): Promise<Driver> => {
  return axios.get(`/admin/get-driver-profile/${id}`);
};

type QueryFnType = typeof getDriverProfile;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useDriverProfile = ({ id, config }: UseOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-driver'],
    queryFn: () => getDriverProfile(id),
  });
};
