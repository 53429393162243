import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, InputPhone } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';
import * as z from 'zod';
import rectangle from '@/assets/Rectangle.png';
import soat from '@/assets/soat.png';
import license from '@/assets/license.png';
import { useSelectFile } from '@/hooks/useSelectFile';
import { useEffect, useState } from 'react';
import { uploadImage } from '../../api/uploadImage';
import plus from '@/assets/plus.png';
import { DriverError, DriverProfile, DriverValues } from './driverTypes';
import { addDriver } from '../../api/driver/addDriver';
import { useNotificationStore } from '@/stores/notifications';
import { useNavigate } from 'react-router-dom';
import placeHolder from '@/assets/placeholder.jpg';
import { editDriver } from '../../api/driver/editDriver';
import dummy from '@/assets/dummy_license.jpg';
import { useVehicles } from '../../api/vehicle/getVehicleList';
import { upperFirst } from 'lodash';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';
import moment from 'moment';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const schema = z.object({
  name: z
    .string()
    .min(1, 'Please enter driver name')
    .max(30, 'Please enter maximum 30 letters')
    .regex(/^[^\d]+$/, 'User name cannot contain numbers'),
  email: z.string().min(1, 'Please enter email'),
  phoneNumber: z.custom(isValidMobile, isValidMobileMsg),
  date: z
    .string()
    .min(1, 'Please enter date of birth')
    .refine(
      (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age >= 18;
      },
      {
        message: 'Age must be at least 18 years',
      }
    ),
  licenseNumber: z.string().min(1, 'Please enter license number'),
  licenseExpiry: z
    .string()
    .min(1, 'Please enter license expiry date')
    .refine(
      (expire) => {
        let today = new Date();
        let expireDate = new Date(expire);

        if (expireDate <= today) {
          return false;
        }
        return true;
      },
      {
        message: 'License is expired',
      }
    ),
  insuranceExpiry: z
    .string()
    .min(1, 'Please enter insurance expiry date')
    .refine(
      (expire) => {
        let today = new Date();
        let expireDate = new Date(expire);

        if (expireDate <= today) {
          return false;
        }
        return true;
      },
      {
        message: 'Insurance is expired',
      }
    ),
});

const AddDriver = ({ driver }: { driver?: DriverProfile }) => {
  const { data, isLoading, isFetching } = useVehicles();
  const {
    file: driverFile,
    preview: driverImgPreview,
    handleChange: driverImgChange,
  } = useSelectFile();
  const {
    file: insuraceFile,
    handleChange: insuranceChange,
    preview: pdfPreview,
    pdfName: insuranceName,
  } = useSelectFile();
  const {
    file: soatFile,
    handleChange: soatChange,
    preview: soatPreview,
    pdfName: soatName,
  } = useSelectFile();
  const {
    file: licFrontFile,
    preview: licFrontPreview,
    handleChange: licFrontChange,
  } = useSelectFile();
  const {
    file: licBackFile,
    preview: licBackPreview,
    handleChange: licBackChange,
  } = useSelectFile();
  const { file: proofFile, preview: proofPreview, handleChange: proofChange } = useSelectFile();
  const {
    file: vehiclePlateFile,
    preview: vehiclePlatePreview,
    handleChange: vehiclePlatechange,
  } = useSelectFile();

  const {
    file: vehicleFile,
    preview: vehiclePreview,
    handleChange: vehiclechange,
  } = useSelectFile();

  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [vehicleType, setVehicleType] = useState<string>('');
  const [vehicleError, setVehicleError] = useState<boolean>(false);
  const [error, setError] = useState<DriverError>({
    driverImg: false,
    insuranceImg: false,
    soatImg: false,
    licFront: false,
    licBack: false,
    vehicleImg: false,
    vehiclePlateImg: false,
    proof: false,
  });

  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (driver) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [driver]);

  useEffect(() => {
    let isError = false;
    let errorObj = { ...error };
    if (driverFile) {
      errorObj = { ...errorObj, driverImg: false };
      isError = false;
    }
    if (insuraceFile) {
      errorObj = { ...errorObj, insuranceImg: false };
      isError = false;
    }
    if (soatFile) {
      errorObj = { ...errorObj, soatImg: false };
      isError = false;
    }
    if (licFrontFile) {
      errorObj = { ...errorObj, licFront: false };
      isError = false;
    }
    if (licBackFile) {
      errorObj = { ...errorObj, licBack: false };
      isError = false;
    }
    if (proofFile) {
      errorObj = { ...errorObj, proof: false };
      isError = false;
    }
    if (vehiclePlateFile) {
      errorObj = { ...errorObj, vehiclePlateImg: false };
      isError = false;
    }
    if (vehicleFile) {
      errorObj = { ...errorObj, vehicleImg: false };
      isError = false;
    }
    if (!isError) {
      setError(errorObj);
    }
  }, [
    driverFile,
    insuraceFile,
    soatFile,
    licFrontFile,
    licBackFile,
    proofFile,
    vehiclePlateFile,
    vehicleFile,
  ]);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;
  
    let updatedVehicleType;
    if (isChecked) {
      updatedVehicleType = [...vehicleType, selectedValue];
    } else {
      updatedVehicleType = vehicleType.filter(id => id !== selectedValue);
    }
    updatedVehicleType = Array.from(new Set(updatedVehicleType)).filter(id => data?.data?.find(vehicle => vehicle._id === id));
    setVehicleType(updatedVehicleType);
  };

  const handleSubmit = async (values: DriverValues) => {
    try {
      setLoading(true);
      let driverImage = driver?.data.image;
      let insuranceImage = driver?.data.documents?.images?.insurance;
      let soatImage = driver?.data.documents?.images?.soat;
      let licenseFrontImage = driver?.data.documents?.images?.licenseFront;
      let licenseBackImage = driver?.data.documents?.images?.licenseBack;
      let plateImage = driver?.data.documents?.images?.vehiclePlateImage;
      let proofImage = driver?.data.documents?.images?.idProof;
      let vehicleImage = driver?.data.documents?.images?.vehicle;

      if (driverFile) {
        const driverResponse = await uploadImage({ file: driverFile });
        driverImage = driverResponse.url;
      }
      if (insuraceFile) {
        const insuranceResponse = await uploadImage({ file: insuraceFile });
        insuranceImage = insuranceResponse.url;
      }
      if (soatFile) {
        const soatResponse = await uploadImage({ file: soatFile });
        soatImage = soatResponse.url;
      }
      if (licFrontFile) {
        const frontResponse = await uploadImage({ file: licFrontFile });
        licenseFrontImage = frontResponse.url;
      }
      if (licBackFile) {
        const backResponse = await uploadImage({ file: licBackFile });
        licenseBackImage = backResponse.url;
      }
      if (proofFile) {
        const proofResponse = await uploadImage({ file: proofFile });
        proofImage = proofResponse.url;
      }
      if (vehiclePlateFile) {
        const plateResponse = await uploadImage({ file: vehiclePlateFile });
        plateImage = plateResponse.url;
      }
      if (vehicleFile) {
        const vehicleResponse = await uploadImage({ file: vehicleFile });
        vehicleImage = vehicleResponse.url;
      }

      let isError = false;
      let errorObj = { ...error };
      if (!driverImage) {
        errorObj = { ...errorObj, driverImg: true };
        isError = true;
      }
      if (!insuranceImage) {
        errorObj = { ...errorObj, insuranceImg: true };
        isError = true;
      }
      if (!soatImage) {
        errorObj = { ...errorObj, soatImg: true };
        isError = true;
      }
      if (!licenseFrontImage) {
        errorObj = { ...errorObj, licFront: true };
        isError = true;
      }
      if (!licenseBackImage) {
        errorObj = { ...errorObj, licBack: true };
        isError = true;
      }
      if (!plateImage) {
        errorObj = { ...errorObj, vehiclePlateImg: true };
        isError = true;
      }
      if (!vehicleImage) {
        errorObj = { ...errorObj, vehicleImg: true };
        isError = true;
      }
      if (!proofImage) {
        errorObj = { ...errorObj, proof: true };
        isError = true;
      }

      if (isError) {
        setError(errorObj);
        return;
      }

      if (vehicleType === '') {
        setVehicleError(true);
        return;
      }

      if (vehicleType !== '') {
        setVehicleError(false);
      }

      const payload = {
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        driverImage: driverImage ?? '',
        insurance: insuranceImage ?? '',
        insuranceExpiry: values.insuranceExpiry ?? '',
        soat: soatImage ?? '',
        licFrontImage: licenseFrontImage ?? '',
        licBackImage: licenseBackImage ?? '',
        idProof: proofImage ?? '',
        vehiclePlateImage: plateImage ?? '',
        vehicle: vehicleImage ?? '',
        licenseNumber: values.licenseNumber,
        licenseExpiry: values.licenseExpiry,
        date: values.date,
        vehicleType: vehicleType,
      };

      if (driver && driver?.data.id) {
        await editDriver(driver?.data.id, payload);
        addNotification({
          type: 'success',
          title: 'Driver updated successfuly',
        });
      } else {
        await addDriver(payload);
        addNotification({
          type: 'success',
          title: 'Driver added successfuly',
        });
      }
      setLoading(false);
      navigate('/drivers');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (driver?.data.id) {
      setVehicleType(driver?.data.vehicleType);
    }
    if (vehicleType !== '') {
      setVehicleError(false);
    }
  }, [driver]);

  useEffect(() => {
    if (data?.data) {
      setVehicleType(data?.data[0]?._id);
    }
  }, [data]);

  useEffect(() => {
    if (vehicleType !== '') {
      setVehicleError(false);
    }
  }, [vehicleType]);

  function convertDateFormat(date: any) {
    if (/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date) || date == null) {
      return date; // Return unchanged if already in the desired format
    } else {
      var parts = date?.split('/');
      var month = parts[1].length === 1 ? '0' + parts[1] : parts[1];
      var day = parts[0].length === 1 ? '0' + parts[0] : parts[0];
      var formattedDate = parts[2] + '-' + month + '-' + day;
      //   var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
      return formattedDate;
    }
  }

  return (
    <>
      {reload || isLoading || isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <NewSpinner />
        </div>
      ) : (
        <ContentLayout title={driver?.data.id ? 'Edit Driver' : 'Add Driver'}>
          <div className="card shadow border-0 rounded add-user-form mt-4 ">
            <h5 className="font-bold mb-1">Basic Info</h5>
            <div className="my-4">
              <Form<DriverValues, typeof schema>
                schema={schema}
                onSubmit={handleSubmit}
                options={{
                  defaultValues: {
                    name: driver?.data.name ?? '',
                    email: driver?.data.email ?? '',
                    // date: driver?.data.dob ?? '',
                    date: convertDateFormat(driver?.data?.dob) ?? '',
                    phoneNumber: driver?.data.phoneNumber
                      ? driver?.data.phoneNumber.toString()
                      : '',
                    licenseNumber: driver?.data.documents?.drivingLicense ?? '',
                    licenseExpiry: convertDateFormat(driver?.data.documents?.dateOfExpiry) ?? '',
                    insuranceExpiry:
                      convertDateFormat(driver?.data.documents?.insuranceExpiry) ?? '',
                  },
                }}
              >
                {({ register, formState }) => {
                  let newSoatFile = driver?.data.documents?.images?.soat?.split('.').at(-1);
                  let newInsuranceFile = driver?.data.documents?.images?.insurance
                    ?.split('.')
                    .at(-1);

                  return (
                    <>
                      <label>Driver Image</label>
                      <div>
                        <label htmlFor="driverImage">
                          <div className="photo">
                            {driverImgPreview ? (
                              <img
                                src={driverImgPreview}
                                alt=""
                                className="previewImage setImage"
                              />
                            ) : driver?.data.image ? (
                              <img
                                src={driver?.data.image}
                                className="previewImage setImage"
                                alt=""
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolder;
                                }}
                              />
                            ) : (
                              <img src={plus} alt="" width="60" />
                            )}
                          </div>
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="driverImage"
                          className="d-none"
                          onChange={driverImgChange}
                        />
                        {error.driverImg && (
                          <p className="text-red-500 mt-1 font-semibold">
                            Driver image is required
                          </p>
                        )}
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['name']}
                            registration={register('name')}
                            type="text"
                            label="Driver name"
                            placeholder="Driver Name"
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <div className="prepend-icon">
                            <InputPhone
                              label="Phone Number"
                              error={formState.errors['phoneNumber']}
                              registration={register('phoneNumber')}
                              value={
                                driver?.data.phoneNumber ? driver?.data.phoneNumber.toString() : ''
                              }
                              disabled={driver ? true : false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['email']}
                            registration={register('email')}
                            type="text"
                            label="Email"
                            placeholder="Email"
                            disabled={driver ? true : false}
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            error={formState.errors['date']}
                            registration={register('date')}
                            type="date"
                            label="Date of birth"
                          />
                        </div>
                      </div>

                      <div>
                        <h5 className="font-bold mb-1">License Details</h5>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-4">
                          <label>Driving License(front)</label>
                          <div className="mt-2">
                            <label htmlFor="liceseFront">
                              <div className="photo">
                                {licFrontPreview ? (
                                  <img
                                    src={licFrontPreview}
                                    alt=""
                                    className="previewImage setImage"
                                  />
                                ) : driver?.data.documents?.images?.licenseFront ? (
                                  <img
                                    src={driver?.data.documents?.images?.licenseFront}
                                    className="previewImage setImage"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = dummy;
                                    }}
                                  />
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              id="liceseFront"
                              type="file"
                              accept="image/*"
                              className="d-none"
                              onChange={licFrontChange}
                            />
                            {error.licFront && (
                              <p className="text-red-500 mt-1 font-semibold">
                                License front image is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <label>Driving License(back)</label>
                          <div className="mt-2">
                            <label htmlFor="licenseBack">
                              <div className="photo">
                                {licBackPreview ? (
                                  <img
                                    src={licBackPreview}
                                    alt=""
                                    className="previewImage setImage"
                                  />
                                ) : driver?.data.documents?.images?.licenseBack ? (
                                  <img
                                    src={driver?.data.documents?.images?.licenseBack}
                                    className="previewImage setImage"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = dummy;
                                    }}
                                  />
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              id="licenseBack"
                              type="file"
                              accept="image/*"
                              className="d-none"
                              onChange={licBackChange}
                            />
                            {error.licBack && (
                              <p className="text-red-500 mt-1 font-semibold">
                                License back image is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-4">
                          <label>ID Proof</label>
                          <div className="mt-2">
                            <label htmlFor="proof">
                              <div className="photo">
                                {proofPreview ? (
                                  <img
                                    src={proofPreview}
                                    alt=""
                                    className="previewImage setImage"
                                  />
                                ) : driver?.data.documents?.images?.idProof ? (
                                  <img
                                    src={driver?.data.documents?.images?.idProof}
                                    className="previewImage setImage"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = placeHolder;
                                    }}
                                  />
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              id="proof"
                              type="file"
                              accept="image/*"
                              className="d-none"
                              onChange={proofChange}
                            />
                            {error.proof && (
                              <p className="text-red-500 mt-1 font-semibold">
                                ID proof is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12 col-md-6">
                          <InputField
                            type="text"
                            label="Driving License Number"
                            placeholder="License Number"
                            error={formState.errors['licenseNumber']}
                            registration={register('licenseNumber')}
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <InputField
                            type="date"
                            label="Driving License Expiry"
                            error={formState.errors['licenseExpiry']}
                            registration={register('licenseExpiry')}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-6">
                          <InputField
                            type="date"
                            label="Insurance Expiry"
                            placeholder="Insurance Expiry"
                            error={formState.errors['insuranceExpiry']}
                            registration={register('insuranceExpiry')}
                          />
                        </div>
                      </div>

                      <div className="other-documents mt-4">
                        <h5 className="font-bold">Other Documents</h5>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-4 mb-4">
                          <label>Insurance</label>
                          <div className="mt-2">
                            <label htmlFor="insurance">
                              <div className="photo">
                                {pdfPreview ? (
                                  <>
                                    {insuraceFile?.type === 'application/pdf' ? (
                                      <>
                                        <div className="files-box">
                                          <p>{insuranceName}</p>
                                        </div>
                                      </>
                                    ) : (
                                      <img
                                        src={pdfPreview}
                                        alt=""
                                        className="previewImage setImage"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = placeHolder;
                                        }}
                                      />
                                    )}
                                  </>
                                ) : driver?.data.documents?.images?.insurance ? (
                                  newInsuranceFile === 'pdf' ? (
                                    <object
                                      data={driver?.data.documents?.images?.insurance}
                                      type="application/pdf"
                                      width="80%"
                                      height="80%"
                                      style={{ overflow: 'hidden' }}
                                    ></object>
                                  ) : (
                                    <img
                                      src={driver?.data.documents?.images?.insurance}
                                      className="previewImage setImage"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = placeHolder;
                                      }}
                                      alt=""
                                      width="60"
                                    />
                                  )
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              type="file"
                              id="insurance"
                              className="d-none"
                              accept="image/*, pdf/*"
                              onChange={insuranceChange}
                            />
                            {error.insuranceImg && (
                              <p className="text-red-500 mt-1 font-semibold">
                                Insurance is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-4 mb-4">
                          <label>Soat</label>
                          <div className="mt-2">
                            <label htmlFor="soat">
                              <div className="photo">
                                {soatPreview ? (
                                  <>
                                    {soatFile?.type === 'application/pdf' ? (
                                      <>
                                        <div className="files-box">
                                          <p>{soatName}</p>
                                        </div>
                                      </>
                                    ) : (
                                      <img
                                        src={soatPreview}
                                        className="previewImage setImage"
                                        alt=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = placeHolder;
                                        }}
                                      />
                                    )}
                                  </>
                                ) : driver?.data.documents?.images?.soat ? (
                                  <img
                                    src={driver?.data.documents?.images?.soat}
                                    className="previewImage setImage"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = placeHolder;
                                    }}
                                    alt=""
                                    width="60"
                                  />
                                ) : newSoatFile === 'pdf' ? (
                                  <object
                                    data={driver?.data.documents?.images?.soat}
                                    type="application/pdf"
                                    width="80%"
                                    height="80%"
                                    style={{ overflow: 'hidden' }}
                                  ></object>
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              type="file"
                              id="soat"
                              className="d-none"
                              accept="image/*, pdf/*"
                              onChange={soatChange}
                            />
                            {error.soatImg && (
                              <p className="text-red-500 mt-1 font-semibold">Soat is required</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="vehicle-info">
                        <h5>Vehicle Info</h5>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 col-md-6 mb-4">
                          <label>Vehicle Plate</label>
                          <div className="mt-2">
                            <label htmlFor="vehiclePlate">
                              <div className="photo">
                                {vehiclePlatePreview ? (
                                  <img
                                    src={vehiclePlatePreview}
                                    alt=""
                                    className="previewImage setImage"
                                  />
                                ) : driver?.data.documents?.images?.vehiclePlateImage ? (
                                  <img
                                    src={driver?.data.documents?.images?.vehiclePlateImage}
                                    className="previewImage setImage"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = placeHolder;
                                    }}
                                  />
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              id="vehiclePlate"
                              type="file"
                              accept="image/*"
                              className="d-none"
                              onChange={vehiclePlatechange}
                            />
                            {error.vehiclePlateImg && (
                              <p className="text-red-500 mt-1 font-semibold">
                                Vehicle plate image is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <label>Vehicle</label>
                          <div className="mt-2">
                            <label htmlFor="vehicle">
                              <div className="photo">
                                {vehiclePreview ? (
                                  <img
                                    src={vehiclePreview}
                                    alt=""
                                    className="previewImage setImage"
                                  />
                                ) : driver?.data.documents?.images?.vehicle ? (
                                  <img
                                    src={driver?.data.documents?.images?.vehicle}
                                    className="previewImage setImage"
                                    alt=""
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = placeHolder;
                                    }}
                                  />
                                ) : (
                                  <img src={plus} alt="" width="60" />
                                )}
                              </div>
                            </label>
                            <input
                              id="vehicle"
                              type="file"
                              accept="image/*"
                              className="d-none"
                              onChange={vehiclechange}
                            />
                            {error.vehicleImg && (
                              <p className="text-red-500 mt-1 font-semibold">
                                Vehicle image is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <label className="mb-2"> Service Type</label>
                        <div className="col-12 col-md-6 mb-2">
                          <FormGroup>
                            {data?.data?.map((vehicle) => (
                              <FormControlLabel
                                key={vehicle._id}
                                control={
                                  <Checkbox
                                    checked={vehicleType.includes(vehicle._id)}
                                    onChange={handleChange}
                                    value={vehicle._id}
                                  />
                                }
                                label={upperFirst(vehicle.vehicleType)}
                              />
                            ))}
                          </FormGroup>
                        </div>
                        {vehicleError && (
                          <p className="text-red-500 font-semibold">Please select vehicle type</p>
                        )}
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <Button type="submit" className="px-4" isLoading={loading}>
                            Save
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                }}
              </Form>
            </div>
          </div>
        </ContentLayout>
      )}
    </>
  );
};

export default AddDriver;
