import { Button, Spinner, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import exportIcon from '@/assets/export_icon.png';
import { usePays } from '../../api/paymentsList/getPays';
import { usePagination } from '@/hooks/usePagination';
import { useEffect, useState } from 'react';
import { RideDTO, RideData } from './rideType';
import { upperFirst } from 'lodash';
import moment from 'moment';
import eye from '@/assets/eye.png';
import { paysCsv } from '../../api/csv/paysCsv';
import { useNavigate } from 'react-router-dom';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';
import { Menu, MenuItem } from '@mui/material';

const PaymentList = () => {
  const { page, changePage } = usePagination();
  const navigate = useNavigate();
  const [searchkey, setSearchKey] = useState<string>('');
  const [statuskey, setStatusKey] = useState<string>('');
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data, isLoading, isFetching, refetch } = usePays({
    page: page,
    search: searchkey,
    status: statuskey,
  });

  const options = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Completed',
      value: 'completed',
    },
  ];

  const handleSearch = (val: string) => {
    setSearchKey(val);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleStatus = (value: string) => {
    setStatusKey(value);
  };

  const handleExport = async (type: string) => {
    try {
      setExportLoader(true);
      const fileName = await paysCsv({
        page: page,
        search: searchkey,
        status: statuskey,
        type: type
      });
      window.open(`${process.env.REACT_APP_API_URL}/${fileName?.data}`);
      setExportLoader(false);
    } finally {
      setExportLoader(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [searchkey, statuskey]);

  useEffect(() => {
    refetch();
  }, [page]);


  return (
    <>
      <ContentLayout title="">
        <div className="container my-4">
          <div className="row">
            <div className="col-md-6">
              <h3 className="font-bold">Payments</h3>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{ paddingRight: '0.75rem' }}
                value={searchkey}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <select
                className="form-select"
                value={statuskey}
                onChange={(e) => handleStatus(e.target.value)}
              >
                {options.map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 text-end">
              <Button
                className="px-4"
                variant="outline"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                isLoading={exportLoader}
                startIcon={<img src={exportIcon} width="20" />}
                onClick={handleClick}
              >
                Export
              </Button>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={()=>{handleExport("all");
                  handleClose()}}>All</MenuItem>
                  <MenuItem onClick={()=>{handleExport("filters");
                handleClose()}}>with Filters</MenuItem>
                </Menu>
            </div>
          </div>

          {isLoading || isFetching ? (
            <div className='d-flex justify-content-center align-items-center'>
              <NewSpinner />
            </div>
          ) : (
            <div className="bg-white rounded mt-3">
              <div className="mt-3">
                <Table<RideData>
                  page={page}
                  changePage={changePage}
                  total={data?.count ?? 1}
                  totalPages={data?.totalPages ?? 1}
                  data={data?.data ?? []}
                  columns={[
                    {title: 'Ride ID',field: 'rideUID'},
                    {
                      title: 'User Name',
                      field: 'userName',
                      Cell({ entry: { userName } }) {
                        return <span>{upperFirst(userName ?? "N/A")}</span>
                      }
                    },
                    {
                      title: 'Driver Name',
                      field: 'driverName',
                      Cell({ entry: { driverName } }) {
                        return <span>{upperFirst(driverName ?? "N/A")}</span>
                      }
                    },

                    {
                      title: 'Payment Type',
                      field: 'paymentMode',
                      Cell({ entry: { paymentMode } }) {
                        return <span>{paymentMode ? upperFirst(paymentMode) : 'Cash'}</span>
                      }
                    },
                    {
                      title: 'Amount', field: 'finalPrice',
                      Cell({ entry: { finalPrice } }) {
                        return <span>{finalPrice} sol</span>
                      }
                    },
                    {
                      title: 'Status', field: 'isPaymentCompleted',
                      Cell({ entry: { isPaymentCompleted } }) {
                        return <span>{!isPaymentCompleted ? 'pending' : 'completed'}</span>
                      }
                    },
                    {
                      title: 'Date & Time',
                      field: 'createdAt',
                      Cell({ entry: { createdAt } }) {
                        return <span>{moment(createdAt).format('DD MMM YYYY')}</span>
                      },
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

export default PaymentList;
