import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { General } from '../routes/settings/GeneralSetting';
type settingsDto = {
    data:  General
}

export const getSettings = (): Promise<settingsDto> => {
  return axios.get(`/admin/get-settings`);
};

type QueryFnType = typeof getSettings;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useSettings = ({ config }: UseOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['siteSettings'],
    queryFn: () => getSettings(),
  });
};