import { axios } from "@/lib/axios"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { AxiosResponse } from "axios"
import { useQuery } from "react-query";

export type Notification = {
    read: boolean;
    _id: string;
    title: string;
    description: string;
    user: string;
    type: string;
    createdAt: string;
    updatedAt: string;
};

export const getAllNotifications = (page:number): Promise<AxiosResponse<Notification[]>> => {
    return axios.get(`/admin/all-notifications?page=${page ?? 1}`)
};

type QueryFnType = typeof getAllNotifications;

type useNotificationData = {
    config?: QueryConfig<QueryFnType>;
    page: number;
};

export const useNotifications = ({ config, page }: useNotificationData = { page: 1 }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['Notifications'],
        queryFn: () => getAllNotifications(page)
    })
}
