import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import auto from '@/assets/auto_dash.png';
import revenueImg from '@/assets/revenue.png';
import rickshaw from '@/assets/rickshaw.png';
import cross from '@/assets/cross_icon.png';
import driver from '@/assets/driver_dash.png';
import '../dashboard.css';
import { Button, ConfirmationDialog, Table } from '@/components/Elements';
import moment from 'moment';
import { Switch } from '@mui/material';
import eye from '@/assets/eye.png';
import trash from '@/assets/delete.png';
import { useNotificationStore } from '@/stores/notifications';
import { changeUserStatus } from '../../api/changeUserStatus';
import { deleteUser } from '../../api/deleteUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchUsers } from '../../api/getUser';
import { UserDTO } from '../users/userType';
import { RevenueChart } from '@/components/Charts/RevenueChart';
import { ProgressChart } from '@/components/Charts/ProgressChart';
import TripChart from '@/components/Charts/TripChart';
import { DonutChart } from '@/components/Charts/DonutChart';
import { getRevenue } from '../../api/getUserAndOrders';
import { getUserRevenue } from '../../api/getUserRegisterRevenue';
import { getUserData } from '../../api/getUserData';
import { getTripData } from '../../api/getTripData';
import { useDashboard } from '../../api/getDashboard';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';
import { useInView } from 'react-intersection-observer';

const LazyLoadedRevenueChart = ({ data }) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return <div ref={ref}>{isVisible && <RevenueChart data={data} />}</div>;
};

export const Reports = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const { data, isLoading, isFetching, refetch } = useFetchUsers({
    page: 1,
    x: '',
    status: '',
  });
  const { data: dashboardData, isLoading: dashboardLoading } = useDashboard();

  // const options = [
  //   {
  //     label: 'Status',
  //     value: '',
  //   },
  //   {
  //     label: 'Active',
  //     value: 'active',
  //   },
  //   {
  //     label: 'Inactive',
  //     value: 'inactive',
  //   },
  // ];

  const [userRevenue, setUserRevenue] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [revenue, setRevenue] = useState();
  const [trip, setTrip] = useState();
  const [userData, setUserData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [revenueLoading, setRevenueLoading] = useState<boolean>(false);
  const [tripLoading, setTripLoading] = useState<boolean>(false);

  const getRevenueData = async (val: string) => {
    setRevenueLoading(true);
    const data = await getRevenue(val);
    setRevenue(data?.data?.data);
    setRevenueLoading(false);
  };

  const getUserRevenueData = async () => {
    setLoading(true);
    const data = await getUserRevenue();
    setUserRevenue(data?.data?.data);
    setLoading(false);
  };

  const getUserDataInfo = async (val: string) => {
    setLoading(true);
    const data = await getUserData(val);
    setUserData(data?.data?.data);
    setLoading(false);
  };

  const getTrip = async (val: string) => {
    setTripLoading(true);
    const data = await getTripData(val);
    setTrip(data?.data?.data);
    setTripLoading(false);
  };

  const fetchData = async () => {
    setDataLoading(true);
    try {
      const [userDataRes, userRevenueRes, tripDataRes, revenueDataRes] = await Promise.all([
        getUserData('weekly'),
        getUserRevenue(),
        getTripData('weekly'),
        getRevenue('week'),
      ]);
      setUserData(userDataRes?.data?.data);
      setUserRevenue(userRevenueRes?.data?.data);
      setTrip(tripDataRes?.data?.data);
      setRevenue(revenueDataRes?.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleStatus = async (id: string) => {
    await changeUserStatus(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'Status changed successfuly',
    });
    setDeleteLoader(false);
  };

  const handleDelete = async (id: string) => {
    setDeleteLoader(true);
    await deleteUser(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'User deleted successfuly',
    });
    setDeleteLoader(false);
  };

  useEffect(() => {
    refetch();
  }, [deleteLoader]);

  if (isLoading || dashboardLoading || dataLoading) {
    return (
      <ContentLayout title="Reports">
        <NewSpinner size="xl" />
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title="Reports">
      <div className="container mt-4">
        <div className="row gx-4">
          <div className="col-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/users')}>
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row align-items-center mb-3">
                  <div className="col-3">
                    <img src={auto} alt="icon" className="dashboard-img" />
                  </div>
                  <div className="col-9">
                    <h5 className="f-16 semibold mb-0">Registered User</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="f-30 bold">{dashboardData?.data?.allUser}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-4"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/payments')}
          >
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row  align-items-center mb-3">
                  <div className="col-3">
                    <img src={revenueImg} alt="icon" className="dashboard-img" />
                  </div>
                  <div className="col-9">
                    <h5 className="f-16 semibold mb-0">Total Revenue</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="f-30 bold">
                      {dashboardData?.data?.revenue
                        ? dashboardData?.data?.revenue.toFixed(2) + ' sol'
                        : ''}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/vehicle')}>
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row  align-items-center mb-3">
                  <div className="col-3">
                    <img src={rickshaw} alt="icon" className="dashboard-img" />
                  </div>
                  <div className="col-9">
                    <h5 className="f-16 semibold mb-0">Vehicle Type</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="f-30 bold orange">{dashboardData?.data?.vehicleCount}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 gx-4">
          <div className="col-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/rides')}>
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row  align-items-center mb-3">
                  <div className="col-3">
                    <img src={cross} alt="icon" className="dashboard-img" />
                  </div>
                  <div className="col-9">
                    <h5 className="f-16 mb-0 semibold">Total Number of Rides</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="f-30 bold orange">{dashboardData?.data?.rideCount}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/rides')}>
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row  align-items-center mb-3">
                  <div className="col-3">
                    <img src={cross} alt="icon" />
                  </div>
                  <div className="col-9">
                    <h5 className="f-16 semibold mb-0">Rides Canceled</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="f-30 bold orange">{dashboardData?.data?.cancelled}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4" style={{ cursor: 'pointer' }} onClick={() => navigate('/drivers')}>
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row  align-items-center mb-3">
                  <div className="col-3">
                    <img src={driver} alt="icon" />
                  </div>
                  <div className="col-9">
                    <h5 className="f-16 semibold mb-0">Number of Drivers</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="f-30 bold orange">{dashboardData?.data?.driverCount}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="card border-0">
              <div className="card-content-one">
                <div className="row  align-items-center mb-3">
                  <h3>Users</h3>
                  <Table<UserDTO>
                    page={1}
                    changePage={() => {}}
                    totalPages={data?.totalPages}
                    total={data?.count ?? 0}
                    data={data?.data ?? []}
                    dashboard={true}
                    columns={[
                      { title: 'Customer Name', field: 'name' },
                      { title: 'Email', field: 'email' },
                      {
                        title: 'SignUp Date',
                        field: 'createdAt',
                        Cell({ entry: { createdAt } }) {
                          const newDate = moment(createdAt).format('DD MMM YYYY');
                          return newDate;
                        },
                      },
                      { title: 'Mobile Number', field: 'phoneNumber' },
                      {
                        title: 'Status',
                        field: 'isActive',
                        Cell({ entry: { status, id } }) {
                          return (
                            <>
                              <div className="d-flex align-items-center justify-content-center">
                                <Switch
                                  color="primary"
                                  defaultChecked={status === 'active'}
                                  onChange={() => handleStatus(id)}
                                />
                              </div>
                            </>
                          );
                        },
                      },
                      {
                        title: 'Action',
                        field: 'id',
                        Cell({ entry: { id } }) {
                          return (
                            <>
                              <Button
                                tabIndex={-1}
                                className="icon me-2"
                                variant="outline"
                                title="View"
                                onClick={() => navigate(`/view-user/${id}`)}
                              >
                                <img src={eye} className="h-4" alt="" />
                              </Button>
                              <ConfirmationDialog
                                triggerButton={
                                  <Button
                                    tabIndex={-1}
                                    className="icon me-2"
                                    variant="outline"
                                    title="Delete"
                                  >
                                    <img src={trash} className="h-5" alt="" />
                                  </Button>
                                }
                                confirmButton={
                                  <Button
                                    variant="primary"
                                    className="expanded-btn"
                                    onClick={() => handleDelete(id)}
                                    isLoading={deleteLoader}
                                  >
                                    Delete
                                  </Button>
                                }
                                title="Delete User"
                                icon="danger"
                                body="Are you sure you want to delete this user ?"
                              />
                            </>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pending-orders mt-4">
          <div className="col-12 col-md-7 col-lg-8">
            <div className="card p-4 rounded shadow dash-card border-0">
              <div className="card-toper mb-3 d-flex align-items-center justify-content-between">
                <h5 className="f-18 semi-bold mb-0">Revenue</h5>
              </div>
              <div className="card-content ">
                <div className="col-12 menuselect">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      getRevenueData(e.target.value);
                    }}
                  >
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
                <div className="revenue-total">
                  {revenueLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <NewSpinner />
                    </div>
                  ) : (
                    <LazyLoadedRevenueChart data={revenue} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-4">
            <div className="card p-4 rounded shadow dash-card border-0">
              <div className="card-toper mb-3">
                <h5 className="f-18 semi-bold mb-0">Admin Revenue</h5>
              </div>
              <div className="today-progress">
                {userRevenue && <ProgressChart data={userRevenue} />}
              </div>
            </div>
          </div>
        </div>
        <div className="row pending-orders mt-4">
          <div className="col-12 col-md-7 col-lg-8">
            <div className="card p-4 rounded shadow dash-card border-0">
              <div className="card-toper mb-3 d-flex align-items-center justify-content-between">
                <h5 className="f-18 semi-bold mb-0">Trip</h5>
              </div>
              <div className="card-content ">
                <div className="col-12 menuselect">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      getTrip(e.target.value);
                    }}
                  >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
                {tripLoading && !trip ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <NewSpinner />
                  </div>
                ) : (
                  <div className="revenue-total"><TripChart data={trip} /></div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-4">
            <div className="card p-4 rounded shadow dash-card border-0">
              <div className="card-toper mb-3 d-flex align-items-center justify-content-between">
                <h5 className="f-18 semi-bold mb-0">User Data</h5>
              </div>
              <div className="card-content ">
                <div className="col-12 menuselect">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      getUserDataInfo(e.target.value);
                    }}
                  >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>

                {!loading && (
                  <div className="revenue-total">
                    {userData && <DonutChart userData={userData} />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
