import { axios } from "@/lib/axios"
import { DriverData, DriverProfile } from "../../routes/drivers/driverTypes"

type DriverDTO = {
    name: string,
    email: string,
    phoneNumber: string,
    driverImage: string,
    insurance: string,
    soat: string,
    licFrontImage:string,
    licBackImage: string,
    idProof: string,
    vehiclePlate: string,
    licenseNumber: string,
    licenseExpiry: string,
    date: string,
    vehicleType: string
}

export const editDriver = (id: string, payload: DriverDTO): Promise<DriverDTO> => {
    return axios.post(`/admin/edit-driver/${id}`, payload)
}