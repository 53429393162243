import clsx from 'clsx';
import * as React from 'react';

import logo from '@/assets/logo.png';
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
  content?: string;
  img?: string;
  className?: string;
};

export const LoginLayout = ({ children, title, img, className, content }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="login-main">
        <div className="row min-h-screen p-0 m-0 login-rw">
          <div
            className={clsx('col-12 col-md-6 loginBGcol relative', className)}
          >
            <div className="left-login-cnt d-flex justify-content-center align-items-center flex-column h-100">
              <div className="login-logo text-left w-100">
                <Link className="flex text-white" to="/">
                  <img className="custom-logo w-auto" src={logo} alt="Workflow" />
                </Link>
              </div>
              <h2 className="f-28 mt-2 text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
              <p className="text-white f-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam, orci sit amet sollicitudin scelerisque</p>
            </div>
          </div>
          <div className="col-12 col-md-6 login-right-col">
            <div className="login-form pt-2 pb-2 ps-5 pe-4 ">
              <div className="bg-white">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
