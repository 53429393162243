import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { useUpdateProfile } from '../api/updateProfile';
import { Button } from '@/components/Elements';
import { Form, InputField, InputPhone } from '@/components/Form';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';

import { ContentLayout } from '@/components/Layout';
import { User, UserResponse } from '@/features/auth/types';
import { useAuth } from '@/lib/auth';

type CreateProfile = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
};

const schema = z.object({
  first_name: z
    .string()
    .min(1, 'Please enter customer name')
    .regex(/^[^\d]+$/, 'First name cannot contain numbers'),
  last_name: z
    .string()
    .min(1, 'Please enter customer name')
    .regex(/^[^\d]+$/, 'Last name cannot contain numbers'),
  phone_number: z.custom(isValidMobile, isValidMobileMsg),
  email: z.string().min(1, 'Please enter email address'),

  // address: z.string().min(1, 'Please enter favorite address'),
  // other_address: z.string().min(1, 'Please enter other address'),
});

export const Profile = ({ profile }: { profile?: User }) => {
  const { user } = useAuth();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  //   const discussionQuery = useProfile({ id });
  const updateProfileMutation = useUpdateProfile();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const changeClicked = () => {
    inputFileRef?.current?.click();
  };

  const handleSubmit = async (values: CreateProfile) => {
    try {
      setLoading(true);

      const payload: any = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
      };

      await updateProfileMutation.mutateAsync({ data: payload, id: user?.id });

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ContentLayout title={'Profile'}>
      <br></br>
      <p className="f-20">Please enter the following details</p>
      <div className="my-4">
        {!reload && (
          <Form<CreateProfile, typeof schema>
            onSubmit={handleSubmit}
            schema={schema}
            options={{
              defaultValues: {
                first_name: user?.first_name,
                last_name: user?.last_name,
                email: user?.email,
                phone_number: user?.phone_number,
              },
            }}
          >
            {({ register, formState }) => (
              <>
                <div className="row mt-4">
                  <div className="col-6">
                    <InputField
                      error={formState.errors['first_name']}
                      registration={register('first_name', {
                        // value: profile?.profile,
                      })}
                      type="text"
                      label="First name"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-6">
                    <InputField
                      error={formState.errors['last_name']}
                      registration={register('last_name', {
                        // value: profile?.profile,
                      })}
                      type="text"
                      label="Last name"
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-6">
                    <InputPhone
                      value={user?.phone_number}
                      label="Phone Number"
                      error={formState.errors['phone_number']}
                      registration={register('phone_number', {
                        value: user?.phone_number,
                      })}
                    />
                  </div>

                  <div className="col-6">
                    <InputField
                      error={formState.errors['email']}
                      registration={register('email', {})}
                      type="text"
                      label="Email"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-4">
                    <Button isLoading={loading} type="submit" className="">
                      Update
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </div>
    </ContentLayout>
  );
};
