import clsx from 'clsx';
import * as React from 'react';

type FieldWrapperProps = {
  label?: string;
  className?: string;
  children: React.ReactNode;
  error?: any;
  description?: string;
  blueLabel?: boolean;
  floating?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, className, floating, error, children, blueLabel = false } = props;
  return (
    <div className="position-relative">
      <label className={clsx('block text-sm font-medium text-gray-700', className)}>
        {!floating && <span className={clsx(blueLabel && 'text-primary')}>{label}</span>}
        <div className="mt-1">{children}</div>
      </label>

      {error?.message && (
        <div role="alert" aria-label={error.message} className="text-sm font-semibold text-red-500 mt-1">
          {error.message}
        </div>
      )}
    </div>
  );
};
