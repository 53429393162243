import { axios } from "@/lib/axios"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { VehicleDTO, VehicleData } from "../../routes/vehicle/vehicleType";

export const getVehicleList = (page: number, searchKey: string, statusKey: string): Promise<VehicleDTO> => {
    return axios.get(`/admin/all-vehicle?page=${page ?? 1}&searchKey=${searchKey ?? ''}&statusKey=${statusKey ?? ''}`)
};

export const getAllVehicles = (): Promise<VehicleDTO> => {
    return axios.get(`/admin/all-vehicles`)
};

type QueryFnType = typeof getVehicleList;

type useVehicleList = {
    config?: QueryConfig<QueryFnType>,
    page: number,
    searchKey: string,
    statusKey: string
};

export const useVehicles = ({ config, page, searchKey, statusKey }: useVehicleList = {
    page: 1,
    searchKey: '',
    statusKey: ''
}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['Vehicles'],
        queryFn: () => getVehicleList(page, searchKey, statusKey)
    })
}