import React from 'react';
import ReactApexChart from 'react-apexcharts';

export class RevenueChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Revenue',
          data: this.props.data.revenue,
        },
      ],
      options: {
        colors: ['#6CF102', '#FFF2D3'],
        chart: {
          type: 'area',
          height: 300,
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: this.props.data.date,
          title: {
            text: 'Week  and Month data',
          },
        },
        yaxis: {
          title: {
            text: 'Revenue',
          },
          min: 1,
          max: 100000,
        },
        legend: {
          horizontalAlign: 'left',
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    // Check if new data has been received
    if (prevProps.data !== this.props.data) {
      // Update series data and x-axis categories
      this.setState({
        series: [
          {
            name: 'Revenue',
            data: this.props.data.revenue,
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.props.data.date,
          },
        },
      });
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={300}
        />
      </div>
    );
  }
}
