import { Button, Spinner, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import exportIcon from '@/assets/export_icon.png';
import { usePays } from '../../api/paymentsList/getPays';
import { usePagination } from '@/hooks/usePagination';
import { useEffect, useState } from 'react';
import { upperFirst } from 'lodash';
import moment from 'moment';
import eye from '@/assets/eye.png';
import placeHolderProfile from '@/assets/placeholder.jpg';
import { paysCsv } from '../../api/csv/paysCsv';
import { Navigate, useNavigate } from 'react-router-dom';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';
import { usePayouts } from '../../api/payouts/getPayouts';
import { DriverDTO, DriverData } from './payoutType';
import { payoutCsv } from '../../api/csv/payoutCsv';
import ViewPayout from './ViewPayout';
import { Menu, MenuItem } from '@mui/material';

const Payout = () => {
  const { page, changePage } = usePagination();
  const navigate = useNavigate();
  const [searchkey, setSearchKey] = useState<string>('');
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading, isFetching, refetch } = usePayouts({
    page: page,
    search: searchkey,
  });

  const handleSearch = (val: string) => {
    setSearchKey(val);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleProp = (driverId: DriverData, amount: number, paymentId: string, createdAt: string) => {
    let payoutDetails = {};
    let payoutCreatedAt = createdAt
    payoutDetails = { ...driverId, amount, paymentId, payoutCreatedAt };
    navigate('/view-payout', {
      state: { payoutDetails }
    })
  }

  const handleExport = async (type: string) => {
    try {
      setExportLoader(true);
      const fileName = await payoutCsv({
        page: page,
        search: searchkey,
        type
      });
      window.open(`${process.env.REACT_APP_API_URL}/${fileName?.data}`);
      setExportLoader(false);
    } finally {
      setExportLoader(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [searchkey]);

  useEffect(() => {
    refetch();
  }, [page]);


  return (
    <>
      <ContentLayout title="">
        <div className="container my-4">
          <div className="row">
            <div className="col-md-6">
              <h3 className="font-bold">Payouts</h3>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{ paddingRight: '0.75rem' }}
                value={searchkey}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="col-md-7 text-end">
            <Button
                className="px-4"
                variant="outline"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                isLoading={exportLoader}
                startIcon={<img src={exportIcon} width="20" />}
                onClick={handleClick}
              >
                Export
              </Button>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={()=>{handleExport("all");
                  handleClose()}}>All</MenuItem>
                  <MenuItem onClick={()=>{handleExport("filters");
                handleClose()}}>with Filters</MenuItem>
                </Menu>
            </div>
          </div>

          {isLoading || isFetching ? (
            <div className='d-flex justify-content-center align-items-center'>
              <NewSpinner />
            </div>
          ) : (
            <div className="bg-white rounded mt-3">
              <div className="mt-3">
                <Table<DriverDTO>
                  page={page}
                  changePage={changePage}
                  total={data?.count ?? 1}
                  totalPages={data?.totalPages ?? 1}
                  data={data?.data ?? []}
                  columns={[
                    {
                      title: 'Driver Name',
                      field: 'driverId',
                      Cell({ entry: { driverId } }) {
                        return (
                          <>

                            <div className='d-flex align-items-center gap-3'>
                              <img
                                src={driverId?.image}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  position: "relative",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolderProfile;
                                }}
                                alt=""
                              />
                              <span style={{
                                position: 'relative',

                              }}
                              >{upperFirst(driverId?.name)}</span>
                            </div>
                          </>
                        )
                      }
                    },

                    {
                      title: 'Payment ID',
                      field: 'paymentId',
                      Cell({ entry: { paymentId } }) {
                        return <span>{paymentId}</span>
                      }
                    },
                    {
                      title: 'Amount', field: 'amount',
                      Cell({ entry: { amount } }) {
                        return <span>{amount} sol</span>
                      }
                    },
                    {
                      title: 'Date',
                      field: 'createdAt',
                      Cell({ entry: { createdAt } }) {
                        return <span>{moment(createdAt).format('DD MMM YYYY')}</span>
                      },
                    },
                    {
                      title: 'Action',
                      field: '_id',
                      Cell({ entry: { driverId, amount, paymentId, createdAt } }) {
                        return (
                          <>
                            <Button
                              tabIndex={-1}
                              className='icon me-2'
                              variant='outline'
                              title='view'
                              onClick={() => handleProp(driverId, amount, paymentId, createdAt)}
                            >
                              <img src={eye} className='h-4' alt="" />
                            </Button>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

export default Payout;
