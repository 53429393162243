import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import * as z from 'zod';
import { addCommision } from '../../api/addCommision';
import { useNotificationStore } from '@/stores/notifications';
import { useState } from 'react';
import { useAuth } from '@/lib/auth';
import { useSettings } from '../../api/siteSettings';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

const schema = z.object({
  adminCommission: z
    .string()
    .min(1, 'Please enter commission')
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .regex(/^(?:[0-9]\d{0,1}|0)$/, 'Commission should not exceed than 2 digits'),
  searchRadius: z
    .string()
    .min(4)
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only'),
  timerValue: z
    .string()
    .min(1)
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .regex(/^(?:[0-9]\d{0,1}|0)$/, 'timerValue should not exceed than 2 digits')
    .refine((data: any) => data.timerValue !== '0', {
      message: 'timerValue should not be zero',
    }),
  searchPeriod: z
    .string()
    .min(1)
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .refine((data: any) => data.timerValue !== '0', {
      message: 'searchPeriod should not be zero seconds',
    }),
  minimumRidesForDebt: z
    .string()
    .min(1)
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .regex(/^(?:[0-9]\d{0,1}|0)$/, 'timerValue should not exceed than 2 digits')
    .refine((data: any) => data.minimumRidesForDebt !== '0', {
      message: 'minimum rides should not be zero',
    }),
});

export type General = {
  adminCommission: string;
  searchRadius: string;
  timerValue: string;
  searchPeriod: string;
  minimumRidesForDebt: string;
};

const GeneralSetting = () => {
  const { addNotification } = useNotificationStore();
  const { user } = useAuth();
  const { data, isLoading , isFetching } = useSettings();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      <ContentLayout title="">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="font-bold">Settings</h3>
            </div>
          </div>

          <div className="bg-white rounded-lg mt-3 p-4">
            <h6 className="font-bold">General Settings</h6>
            <div className="border border-grey-500 rounded-lg p-4 mt-8">
              {isLoading || isFetching ? (
                <div className='d-flex justify-content-center align-items-center'>
                  <NewSpinner />
                </div>
              ) : (
                <Form<General, typeof schema>
                  schema={schema}
                  onSubmit={async (values) => {
                    try {
                      setLoading(true);
                      await addCommision(values);
                      addNotification({
                        type: 'success',
                        title: 'Settings added successfuly',
                      });
                      setLoading(false);
                    } finally {
                      setLoading(false);
                    }
                  }}
                  options={{
                    defaultValues: {
                      adminCommission: data?.data?.adminCommission?.toString() ?? '',
                      searchRadius: data?.data?.searchRadius?.toString() ?? '',
                      timerValue: data?.data?.timerValue?.toString() ?? '',
                      searchPeriod: data?.data?.searchPeriod?.toString() ?? '',
                      minimumRidesForDebt: data?.data?.minimumRidesForDebt?.toString() ?? ''
                    },
                  }}
                >
                  {({ register, formState }) => (
                    <>
                      <div className="row mt-3">
                        <div className="col-md-4">
                          <InputField
                            type="text"
                            label="Admin Commission in %"
                            placeholder="10%"
                            error={formState.errors['adminCommission']}
                            registration={register('adminCommission')}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputField
                            type="text"
                            label="Search Radius(in meters)"
                            placeholder="1000"
                            error={formState.errors['searchRadius']}
                            registration={register('searchRadius')}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputField
                            type="text"
                            label="Timer value(in seconds)"
                            placeholder="30"
                            error={formState.errors['timerValue']}
                            registration={register('timerValue')}
                          />
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-4">
                            <InputField
                              type="text"
                              label="Search Timer(in seconds)"
                              placeholder="600"
                              error={formState.errors['searchPeriod']}
                              registration={register('searchPeriod')}
                            />
                          </div>
                          <div className="col-md-4">
                            <InputField
                              type="text"
                              label="Minimum rides for debt"
                              placeholder="1"
                              error={formState.errors['minimumRidesForDebt']}
                              registration={register('minimumRidesForDebt')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-100 d-flex justify-content-end">
                        <Button
                          type="submit"
                          variant="primary"
                          isLoading={loading}
                          style={{ padding: '5px 60px', marginRight: '20px' }}
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default GeneralSetting;
