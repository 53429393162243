import React from 'react';
import './spinner.css'; // Import CSS file for styling

export const NewSpinner = () => {
  return (
    <div className="cube-container">
      <div className="cube">
        <div className="face face1"></div>
        <div className="face face2"></div>
        <div className="face face3"></div>
        <div className="face face4"></div>
        <div className="face face5"></div>
        <div className="face face6"></div>
      </div>
    </div>
  );
}
