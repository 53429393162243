import { axios } from "@/lib/axios"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { RideDTO } from "../../routes/rides/rideType";


export const getRides = (page: number, search?: string, status?: string): Promise<RideDTO> => {
    if (search || status) {
        return axios.get(`/admin/all-rides?search=${search}&status=${status}`)
    }
    return axios.get(`/admin/all-rides?page=${page ?? 1}`)
};

type QueryFnType = typeof getRides;

type useRidesData = {
    config?: QueryConfig<QueryFnType>,
    page: number,
    search?: string,
    status?: string
}

export const useRides = ({ page, config, search, status }: useRidesData = { page: 1, search: '', status: '' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['Rides'],
        queryFn: () => getRides(page, search, status)
    })
}