import { axios } from "@/lib/axios"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";
import { PayoutResponse } from "../../routes/payouts/payoutType";

export const getPayouts = (page: number = 1, search: string = ''):Promise<PayoutResponse> => {
    return axios.get(`/admin/get-payouts?page=${page}&search=${search}`)
};

type QueryFnType = typeof getPayouts;

type UsePayoutsDTO = {
    config?: QueryConfig<QueryFnType>,
    page: number;
    search?: string;
};

export const usePayouts = ({ config, page, search }: UsePayoutsDTO = { page: 1, search: '' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['Payouts'],
        queryFn: () => getPayouts(page, search)
    })
}