import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";


export type HelpQuery = {
    status: string;
    _id: string;
    role: string;
    service: string;
    query: string;
    name: string;
    email: string;
    phoneNumber: number;
    createdAt: string;
    updatedAt: string;
    response?: string;
}
  
  export type HelpApiResponse = {
    data: HelpQuery[];
    page: number;
    totalPages: number;
    totalCount: number;
    count: number;
  }
  

export const getHelpQueries = (searchKey: string, statusKey: string, date: string, page?: number): Promise<HelpApiResponse> => {
    if (searchKey || statusKey || date) {
        return axios.get(`/admin/get-help-queries?page=${page ?? 1}&searchKey=${searchKey}&statusKey=${statusKey}&date=${date}`);
    }
    return axios.get(`/admin/get-help-queries?page=${page ?? 1}`)
};

type QueryFnType = typeof getHelpQueries;

type useQueries = {
    config?: QueryConfig<QueryFnType>;
    searchKey: string;
    statusKey: string;
    date: string;
    page?:number;
}

export const useHelpQueries = ({ config,searchKey,statusKey,date,page }: useQueries = {page: 1, searchKey: '',statusKey: '', date: '' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['helplist'],
        queryFn: () => getHelpQueries(searchKey,statusKey,date,page)
    })
}