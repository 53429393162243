import React from 'react';
import { useUserData } from '../../api/getUserProfile';
import { useParams } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { ContentLayout } from '@/components/Layout';
import { Spinner } from '@/components/Elements';
import proof from '@/assets/id.jpg';
import placeHolderProfile from '@/assets/placeholder.jpg';
import moment from 'moment';

const ViewUser = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching, refetch } = useUserData({ id: id ?? '1' });
  const joinDate = moment(data?.data?.createdAt).format('DD MMM YYYY');
  return (
    <>
      {isLoading || isFetching ? (
        <Spinner />
      ) : (
        <ContentLayout title="">
          <div className="container my-4">
            <h3 className="font-bold">View User Details</h3>
            <div className="card shadow border-0 rounded add-user-form space-card mt-4">
                <h5 className='f-18 pb-4'>User Information</h5>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-2">
                  <img
                    src={data?.data?.image ? data?.data?.image : placeHolderProfile}
                    className="user-images"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = placeHolderProfile;
                    }}
                  />
                </div>
                <div className="col-12 col-md-8 col-lg-10">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 mb-4">
                      <div className="driver-flex">
                        <div className="driver-left">
                          <b>User Name </b>
                        </div>
                        <div className="driver-rghtt">{data?.data?.name}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 mb-4">
                      <div className="driver-flex">
                        <div className="driver-left">
                          <b>Email </b>
                        </div>
                        <div className="driver-rghtt">{data?.data?.email}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                      <div className="driver-flex">
                        <div className="driver-left">
                          <b>Phone Number </b>
                        </div>
                        <div className="driver-rghtt">{data?.data?.phoneNumber}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3 mb-4">
                      <div className="driver-flex">
                        <div className="driver-left">
                          <b>Since Joined </b>
                        </div>
                        <div className="driver-rghtt">{joinDate}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-4">
                      <div className="driver-flex">
                        <div className="driver-left">
                          <b>Status </b>
                        </div>
                        <div className="d-flex flex-row">
                          <div>
                            <span
                              className={
                                data?.data?.status === 'active' ? 'colored-circle' : 'red-circle'
                              }
                            />
                          </div>
                          <div className="driver-rghtt">{upperFirst(data?.data?.status)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentLayout>
      )}
    </>
  );
};

export default ViewUser;
