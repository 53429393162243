import { useParams } from "react-router-dom"
import { useDriverProfile } from "../../api/driver/getDriverProfile";
import { useEffect } from "react";
import { ContentLayout } from "@/components/Layout";
import AddDriver from "./AddDriver";

export const EditDriver = () => {
    const { id } = useParams();
    const { data, isLoading, refetch } = useDriverProfile({ id: id ?? '1' });

    useEffect(() => {
        refetch();
    }, []);

    return  (
        <div>
            <ContentLayout title="">
                <div>
                    <AddDriver driver={data}/>
                </div>
            </ContentLayout>
        </div>
    )
}