import { Button, ConfirmationDialog, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import '../dashboard.css';
import { useFetchUsers } from '../../api/getUser';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import { useNotificationStore } from '@/stores/notifications';
import { changeUserStatus } from '../../api/changeUserStatus';
import { deleteUser } from '../../api/deleteUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import eye from '@/assets/eye.png';
import trash from '@/assets/delete.png';
import edit from '@/assets/edit_jorge.png';
import { usePagination } from '@/hooks/usePagination';
import { UserDTO, UserData } from './userType';
import { InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { userCsv } from '../../api/csv/userCsv';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

const User = () => {
  const navigate = useNavigate();
  const { page, changePage } = usePagination();
  const [searchVal, setSearchVal] = useState<string>('');
  const [statusVal, setStatusVal] = useState<string>('');
  const { data, isLoading, isFetching, refetch } = useFetchUsers({
    page: page,
    x: searchVal,
    status: statusVal,
  });
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      label: 'Status',
      value: '',
    },
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  const handleStatus = async (id: string) => {
    await changeUserStatus(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'Status changed successfuly',
    });
    setDeleteLoader(false);
  };

  const handleDelete = async (id: string) => {
    setDeleteLoader(true);
    await deleteUser(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'User deleted successfuly',
    });
    setDeleteLoader(false);
  };

  useEffect(() => {
    refetch();
  }, [deleteLoader]);

  const handleSearch = (val: string) => {
    if (val.length !== 0) {
      setSearchVal(val);
      setImmediate(() => refetch());
    } else {
      setSearchVal('');
      setImmediate(() => refetch());
    }
  };

  useEffect(() => {
    refetch();
  }, [statusVal]);

  useEffect(() => {
    refetch();
  }, [page]);

  const handleExport = async (type: string) => {
    try {
      setExportLoader(true);
      const fileName = await userCsv({ x: searchVal, status: statusVal, type });
      const url = process.env.REACT_APP_API_URL;
      window.open(`${url}/${fileName?.data}`);
      setExportLoader(false);
    } finally {
      setExportLoader(false);
    }
  };

  return (
    <>
      <ContentLayout title="">
        <div className="container my-4">
          <div className="row">
            <div className="col-md-6">
              <h3 className="font-bold">Users</h3>
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <Button type="button" variant="primary" onClick={() => navigate('/add-user')}>
                Add User
              </Button>

              <Button
                className="px-4 ms-4"
                variant="outline"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                isLoading={exportLoader}
                onClick={handleClick}
              >
                Export
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleExport('all');
                    handleClose();
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExport('filters');
                    handleClose();
                  }}
                >
                  with Filters
                </MenuItem>
              </Menu>
            </div>
          </div>

          <div className="row my-4 search-bar">
            <div className="col-md-6 d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                style={{ paddingRight: '0.75rem' }}
                placeholder="Search"
                value={searchVal}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <select
                name="location"
                className="form-select"
                onChange={(e) => setStatusVal(e.target.value)}
                value={statusVal}
              >
                {options.map(({ label, value }) => (
                  <option key={label?.toString()} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isLoading || isFetching ? (
            <div className="d-flex justify-content-center align-items-center">
              <NewSpinner />
            </div>
          ) : (
            <div className="bg-white rounded mt-3">
              <div className="row p-4">
                <div className="col-6 md-6">
                  <h5 className="bold f-22">Customers</h5>
                </div>
              </div>
              <div className="row mt-3 w-100 m-auto">
                <Table<UserData>
                  page={page}
                  changePage={changePage}
                  totalPages={data?.totalPages ?? 1}
                  total={data?.count ?? 0}
                  data={data?.data ?? []}
                  columns={[
                    { title: 'User ID', field: 'UID' },
                    { title: 'Customer Name', field: 'name' },
                    { title: 'Email', field: 'email' },
                    {
                      title: 'SignUp Date',
                      field: 'createdAt',
                      Cell({ entry: { createdAt } }) {
                        const newDate = moment(createdAt).format('DD MMM YYYY');
                        return <span>{newDate}</span>;
                      },
                    },
                    { title: 'Mobile Number', field: 'phoneNumber' },
                    {
                      title: 'Status',
                      field: 'isActive',
                      Cell({ entry: { status, id } }) {
                        return (
                          <>
                            <div className="d-flex align-items-center justify-content-center">
                              <Switch
                                color="primary"
                                defaultChecked={status === 'active'}
                                onChange={() => handleStatus(id)}
                              />
                            </div>
                          </>
                        );
                      },
                    },
                    {
                      title: 'Action',
                      field: 'id',
                      Cell({ entry: { id } }) {
                        return (
                          <>
                            <Button
                              tabIndex={-1}
                              className="icon me-2"
                              variant="outline"
                              title="View"
                              onClick={() => navigate(`/view-user/${id}`)}
                            >
                              <img src={eye} className="h-4" alt="" />
                            </Button>
                            <Button
                              tabIndex={-1}
                              variant="outline"
                              onClick={() => navigate(`/user/edit/${id}`)}
                              className="icon me-2"
                              title="Edit"
                            >
                              <img src={edit} className="h-5" alt="" />
                            </Button>
                            <ConfirmationDialog
                              triggerButton={
                                <Button
                                  tabIndex={-1}
                                  className="icon me-2"
                                  variant="outline"
                                  title="Delete"
                                >
                                  <img src={trash} className="h-5" alt="" />
                                </Button>
                              }
                              confirmButton={
                                <Button
                                  variant="primary"
                                  className="expanded-btn"
                                  onClick={() => handleDelete(id)}
                                  isLoading={deleteLoader}
                                >
                                  Delete
                                </Button>
                              }
                              title="Delete User"
                              icon="danger"
                              body="Are you sure you want to delete this user ?"
                            />
                          </>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

export default User;
