import { ContentLayout } from '@/components/Layout'
import React from 'react'
import { useSingleRide } from '../../api/rides/viewRide'
import { useParams } from 'react-router-dom'
import { Spinner } from '@/components/Elements'
import moment from 'moment'
import { upperFirst } from 'lodash';
import placeHolderProfile from '@/assets/placeholder.jpg';


const ViewRide = () => {
    const { id } = useParams();
    const { data, isLoading, isFetching } = useSingleRide({ id: id ?? '' });
    console.log(data);
    return (
        <>
            {isLoading || isFetching ? <Spinner /> :
                <ContentLayout title='View Ride Detail'>
                    <div className="row view-ride">
                        <div className="col-12 col-md-10">
                            <div className="card shadow border-0 rounded add-user-form mt-4">
                                <div className="flex flex-col gap-2">
                                    <div className="p-4 pb-1 detail-col">
                                        <span className="d-block mb-4 details-title font-bold">
                                            <h5> Customer Details </h5>
                                        </span>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left mb-2">
                                                <img
                                                    src={data?.data[0].userImage ?? placeHolderProfile}
                                                    className='user-images'
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = placeHolderProfile
                                                    }}
                                                    alt="" />
                                            </div>
                                            {/* <div className="driver-rghtt">{upperFirst(data?.data[0].userName)}</div> */}
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Name : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].userName)}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Email : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].userEmail)}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Phone Number : </b>
                                            </div>
                                            <div className="driver-rghtt">{data?.data[0].userPhone}</div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="p-4 pb-1  detail-col">
                                        <span className="d-block mb-4 details-title font-bold">
                                            <h5> Driver Details </h5>
                                        </span>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left mb-2">
                                                <img
                                                    src={data?.data[0].driverImage ?? placeHolderProfile}
                                                    className='user-images'
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = placeHolderProfile
                                                    }}
                                                    alt="" />
                                            </div>
                                            {/* <div className="driver-rghtt">{upperFirst(data?.data[0].userName)}</div> */}
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Name : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].driverName)}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Email : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].driverEmail)}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Phone Number : </b>
                                            </div>
                                            <div className="driver-rghtt">{data?.data[0].driverPhone}</div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="p-4 pb-1 detail-col">
                                        <span className="d-block mb-4 details-title font-bold">
                                            <h5> Ride Details </h5>
                                        </span>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b> Pickup Location : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].pickupLocation.name)}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Drop Off Location : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].dropLocation.name)}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Initial Price : </b>
                                            </div>
                                            <div className="driver-rghtt">{data?.data[0].initialPrice}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Final Price : </b>
                                            </div>
                                            <div className="driver-rghtt">{data?.data[0].finalPrice}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Date of Ride : </b>
                                            </div>
                                            <div className="driver-rghtt">{moment(data?.data[0].createdAt).format('DD MMM YYYY')}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Payment Mode :</b>
                                            </div>
                                            <div className="driver-rghtt">{data?.data[0].paymentMode ? upperFirst(data?.data[0].paymentMode) : 'Cash'}</div>
                                        </div>
                                        <div className="flex items-center gap-4 driver-flex mb-2">
                                            <div className="driver-left">
                                                <b>Status : </b>
                                            </div>
                                            <div className="driver-rghtt">{upperFirst(data?.data[0].status)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentLayout>
            }
        </>
    )
}

export default ViewRide