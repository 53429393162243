import { ContentLayout } from "@/components/Layout";
import * as z from 'zod';
import plus from '@/assets/plus.png'
import { Form, InputField, InputPhone } from "@/components/Form";
import { Button, Spinner } from "@/components/Elements";
import { useSelectFile } from "@/hooks/useSelectFile";
import { useEffect, useState } from "react";
import { uploadImage } from "../../api/uploadImage";
import { addUser, editUser } from "../../api/addUser";
import { useNavigate } from "react-router-dom";
import { useNotificationStore } from "@/stores/notifications";
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';
import placeholder from '@/assets/placeholder.jpg';
import { NewSpinner } from "@/components/Elements/Spinner/SpinnerCube";

const schema = z.object({
    name: z
        .string()
        .min(1, 'Please enter user name')
        .max(30, 'Please enter maximum 30 letters')
        .regex(/^[^\d]+$/, 'User name cannot contain numbers'),
    phoneNumber: z.custom(isValidMobile, isValidMobileMsg),

    email: z.string().min(1, 'Please enter email address'),
})

export type AddUser = {
    id?: string;
    name: string;
    email: string;
    phoneNumber: string;
    image?: string
}

export const AddUser = ({ user }: { user?: AddUser }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [imageError, setImageError] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const { handleChange, preview: filePreview, file: userFile } = useSelectFile();
    const { addNotification } = useNotificationStore();

    useEffect(() => {
        if (user) {
            setReload(true);
            setTimeout(() => setReload(false), 0);
        }
    }, [user]);
    useEffect(() => {
        if (userFile) {
            setImageError(false)
        }
    }, [userFile]);

    const handleSubmit = async (values: AddUser) => {
        try {
            setLoading(true);
            let image = user?.image ?? '';
            if (userFile) {
                const response = await uploadImage({ file: userFile })
                image = response.url;

            } else {
                image = user?.image ?? '';
            }
            if (image === '') {
                setImageError(true);
                return;
            }
            const payload = {
                image: image,
                name: values.name,
                phoneNumber: values.phoneNumber,
                email: values?.email
            }

            if (user && user.id) {
                await editUser(user?.id, payload);
                addNotification({
                    type: 'success',
                    title: 'User Updated Successfuly'
                })
            } else {
                await addUser(payload);
                addNotification({
                    type: 'success',
                    title: 'User Added Successfuly'
                })
            }
            setLoading(false);

            navigate('/users')

        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {reload &&
                <div className='d-flex justify-content-center align-items-center'>
                    <NewSpinner />
                </div>}
            <ContentLayout title={user?.id ? 'Edit User' : 'Add User'}>
                <div className="card shadow border-0 rounded add-user-form mt-4 ">
                    <p className="f-20 semi-bold mb-1">Please enter the following details</p>
                    <div className="my-4">
                        {!reload &&
                            <Form<AddUser, typeof schema>
                                schema={schema}
                                onSubmit={handleSubmit}
                                options={{
                                    defaultValues: {
                                        name: user?.name ?? '',
                                        email: user?.email ?? '',
                                        phoneNumber: user?.phoneNumber?.toString() ?? ''
                                    }
                                }}
                            >

                                {({ register, formState }) => (
                                    <>
                                        <label>Image</label>
                                        <div>
                                            <label htmlFor="userInput">
                                                <div className="photo">
                                                    {filePreview ?
                                                        <img src={filePreview} alt="" className="previewImage setImage" />
                                                        : user?.image ? (
                                                            <img
                                                                src={user?.image}
                                                                className="previewImage setImage"
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null
                                                                    currentTarget.src = placeholder
                                                                }}
                                                                alt="" />
                                                        ) :
                                                            <img src={plus} alt="" width="60" />
                                                    }
                                                </div>
                                            </label>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                id="userInput"
                                                className="d-none"
                                                onChange={handleChange}
                                            />
                                            {imageError && <p className="text-red-500 mt-1 font-semibold">Image is Required </p>}
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 col-md-6 mb-4">
                                                <InputField
                                                    error={formState.errors['name']}
                                                    registration={register('name')}
                                                    type="text"
                                                    label="Customer name"
                                                    placeholder="Customer Name"
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="prepend-icon">
                                                    <InputPhone
                                                        label="Phone Number"
                                                        error={formState.errors['phoneNumber']}
                                                        registration={register('phoneNumber')}
                                                        value={user?.phoneNumber?.toString() ?? ''}
                                                        disabled={user ? true: false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-4">
                                                <InputField
                                                    error={formState.errors['email']}
                                                    registration={register('email')}
                                                    type="text"
                                                    label="Email"
                                                    placeholder="Email"
                                                    disabled={user ? true: false}
                                                />
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-12 text-center">
                                                    <Button isLoading={loading} type="submit" className="px-4">
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </Form>
                        }
                    </div>
                </div>
            </ContentLayout >
        </>

    )
}