import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// import { useUser } from '@/api/user/getUser';
import { ContentLayout } from '@/components/Layout';

import { AddUser } from './AddUser';
import { useUserData } from '../../api/getUserProfile';

export const EditUser = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useUserData({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <ContentLayout title="">
        <div>
          <AddUser user={data?.data} />
        </div>
      </ContentLayout>
    </div>
  );
};
