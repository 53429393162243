import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getUserData = (val: String): Promise<[]> => {
  return axios.get(`/admin/get-user-report?value=${val ? val : 'weekly'}`);
};

type QueryFnType = typeof getUserData;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
  val?: string;
};

export const useUserDatas = ({ config, val }: UseOptions = { val: '' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['user-data'],
    queryFn: () => getUserData(val),
  });
};
