import { axios } from "@/lib/axios";

type ImageUpload = {
    file: File
}

type UploadApiResponse = {
    url: string;
}


export const changeAdminImage = (id: string, data: ImageUpload): Promise<UploadApiResponse> => {
    const formData = new FormData();
    formData.append('file', data.file)
    return axios.post(`/admin/upload/${id}`, formData)
}