import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AxiosResponse } from 'axios';
import { FaqData } from '../../routes/faq/AddFaq';


export const getSingleFaq = (id: string): Promise<AxiosResponse<FaqData>> => {
  return axios.get(`/admin/get-single-faq/${id}`);
};


type QueryFnType = typeof getSingleFaq;

type UseFaqData = {
    config?: QueryConfig<QueryFnType>;
    id: string;
};

export const useSingleFaqData = ({ config, id }: UseFaqData ) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['faq'],
    queryFn: () => getSingleFaq(id),
  });
};
