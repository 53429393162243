import { Link } from 'react-router-dom';
import * as z from 'zod';

import google from '@/assets/google_icon.png';
import intuit from '@/assets/intuit.png';
import xero from '@/assets/xero.svg';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import '../routes/auth.css';
import { forgotPassword } from '../api/forgotPassword';
import { useNotificationStore } from '@/stores/notifications';
import { useState } from 'react';

const schema = z.object({
  email: z.string().min(1, 'Please enter your email'),
});

export type ForgotPasswordValues = {
  email: string;
};

type ForgotPasswordProps = {
  onSuccess: () => void;
};

export const ForgotPassword = ({ onSuccess }: ForgotPasswordProps) => {
  const { addNotification } = useNotificationStore();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="login-form-main">
      <h2 className="f-28 bold pb-2">Forgot Password?</h2>
      <p className="f-14 pb-4">Enter your email and we'll send you instructions to reset your password.</p>
      <Form<ForgotPasswordValues, typeof schema>
        onSubmit={async (values) => {
          try {
            setLoading(true);
            await forgotPassword(values);
            addNotification({
              type: 'success',
              title: 'Reset password link is sent to your email address'
            })
            setLoading(false);
            onSuccess();
          } catch (error) {
            setLoading(false);
          }

        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              blueLabel
              error={formState.errors['email']}
              registration={register('email')}
            />

            <div>

              <div className="login-btn text-center mt-5">
                <Button
                  // startIcon={<i className="fa-solid fa-lock" />}
                  isLoading={loading}
                  type="submit"
                  className="w-full  rounded-radius w-75 m-auto"
                >
                  Send Reset Link
                </Button>
              </div>
              <div className='back-btn mt-5'>
                <Link to="/" style={{ textDecoration: 'none', color: '#000', display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.218785 7.12514L5.90842 13.0205C6.04001 13.157 6.21593 13.2324 6.40369 13.2328C6.59146 13.2333 6.76772 13.1587 6.89996 13.0228L7.32099 12.5908C7.59496 12.3092 7.59604 11.8506 7.32339 11.5682L2.54566 6.61757L7.35189 1.68399C7.48412 1.54815 7.55738 1.36682 7.55783 1.17328C7.55829 0.979527 7.48588 0.797854 7.35429 0.661283L6.93528 0.227338C6.80359 0.0908744 6.62777 0.0154543 6.44001 0.0150139C6.25225 0.0145735 6.07598 0.0891677 5.94374 0.225012L0.221192 6.09899C0.0886452 6.23526 0.0155925 6.41745 0.0155546 6.61131C0.0146813 6.80593 0.0868788 6.98835 0.218785 7.12514Z" fill="#150035" />
                  </svg>
                  Back to Login
                </Link>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
