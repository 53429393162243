import { Button, ConfirmationDialog, Table } from '@/components/Elements'
import { ContentLayout } from '@/components/Layout'
import { usePagination } from '@/hooks/usePagination'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFaqData } from '../../api/faq/getFaq';
import { useNotificationStore } from '@/stores/notifications';
import { deleteFaq } from '../../api/faq/deleteFaq';
import eye from '@/assets/eye.png';
import trash from '@/assets/delete.png';
import edit from '@/assets/edit_jorge.png';

type GetFaq = {
    id: string;
    _id: string;
    question: string;
    answer: string;
}

const Faq = () => {
    const navigate = useNavigate();
    const { page, changePage } = usePagination();
    const { addNotification } = useNotificationStore();
    const { data, isLoading, isFetching, refetch } = useFaqData({ page: page });
    const [reload, setReload] = useState<boolean>(false);


    const handleDelete = async (id: string) => {
        await deleteFaq(id);
        addNotification({
            type: 'success',
            title: 'FAQ deleted successfuly'
        });
        setReload(true);
    }

    useEffect(() => {
        refetch();
    }, [reload])

    useEffect(() => {
        refetch();
    }, [page])

    return (
        <>
            <ContentLayout title=''>
                <div className="my-4">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="bold">FAQ Listing</h4>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                            <Button
                                onClick={() => navigate('/add-faq')}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <Table<GetFaq>
                        page={page}
                        changePage={changePage}
                        totalPages={data?.totalPages}
                        total={data?.totalCount}
                        data={data?.data ?? []}
                        columns={[
                            { title: "Question", field: "question" },
                            {
                                title: 'Answer',
                                field: 'answer',
                                Cell({ entry: { answer } }) {
                                    return <span>{`${answer.slice(0, 50)}${answer.length > 50 ? '..............' : ''}`}</span>
                                }
                            },
                            {
                                title: 'Action',
                                field: '_id',
                                Cell({ entry: { _id } }) {
                                    return (
                                        <>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => navigate(`/view-faq/${_id}`)}
                                                className="icon me-2"
                                                variant="outline"
                                                title="View"
                                            >
                                                <img src={eye} className='h-4' alt="" />
                                            </Button>

                                            <Button variant='outline' className='icon me-2' onClick={() => navigate(`/edit-faq/${_id}`)}>
                                                <img src={edit} className='h-5' alt="" />
                                            </Button>

                                            <ConfirmationDialog
                                                triggerButton={
                                                    <Button
                                                        className="icon me-2"
                                                        variant="outline"
                                                        title="Delete"
                                                    >
                                                        <img src={trash} className='h-5' alt="" />
                                                    </Button>
                                                }
                                                confirmButton={
                                                    <Button
                                                        variant='primary'
                                                        className='expanded-btn'
                                                        onClick={() => handleDelete(_id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                }
                                                title='Delete FAQ'
                                                icon='danger'
                                                body="Are you sure you want to delete this FAQ"
                                            />
                                        </>
                                    )

                                }
                            }
                        ]}
                    />
                </div>
            </ContentLayout>
        </>
    )
}

export default Faq