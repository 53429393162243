import { Spinner } from '@/components/Elements'
import { ContentLayout } from '@/components/Layout'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSingleFaqData } from '../../api/faq/getSingleFaq'
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube'

const ViewFaq = () => {
    const { id } = useParams();
    const { data, isLoading, isFetching } = useSingleFaqData({ id: id ?? '' });
    console.log(data);

    return (
        <>
            {isLoading || isFetching ?
                <div className='d-flex justify-content-center align-items-center'>
                    <NewSpinner />
                </div> :
                <ContentLayout title='View FAQ'>
                    <div className='card shadow border-0 rounded mt-4'>
                        <div className="row p-4">
                            <div className="col-md-12 p-4">
                                <label className='font-bold'>FAQ Question</label>
                                <p className='mt-2'>{data?.data?.question}</p>
                            </div>

                            <div className="col-md-12 px-4 py-2">
                                <label className='font-bold'>FAQ Answer</label>
                                <p className='mt-2'>{data?.data?.answer}</p>
                            </div>
                        </div>
                    </div>
                </ContentLayout>
            }
        </>
    )
}

export default ViewFaq