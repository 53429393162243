import { useNavigate } from "react-router-dom"
import { LoginLayout } from "../components/LoginLayout"
import ResetPassword from "../components/ResetPassword"


export const Reset = () => {
    const navigate = useNavigate();
    return (
        <>
            <LoginLayout
                title="Login"
                content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam, orci sit amet sollicitudin scelerisque."
            >
                <ResetPassword onSuccess = {()=> navigate('/auth/login')} />
            </LoginLayout>
        </>
    )
}