import { ContentLayout } from '@/components/Layout';
import React, { useState } from 'react';
import { useDriverProfile } from '../../api/driver/getDriverProfile';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ConfirmationDialog, Spinner } from '@/components/Elements';
import placeHolderProfile from '@/assets/placeholder.jpg';
import moment from 'moment';
import proof from '@/assets/id.jpg';
import license from '@/assets/dummy_license.jpg';
import { upperFirst } from 'lodash';
import { verifyDocuments } from '../../api/driver/verifyDocuments';
import { useNotificationStore } from '@/stores/notifications';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Form, TextAreaField } from '@/components/Form';
import * as z from 'zod';
import { useDisclosure } from '@/hooks/useDisclosure';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

type ResponseValues = {
  reason: string;
};

const schema = z.object({
  reason: z.string().min(1, 'Please enter reason'),
});

const ViewDriver = () => {
  const { id } = useParams();
  const { isOpen, open, close } = useDisclosure();
  const { data, isFetching, isLoading, refetch } = useDriverProfile({ id: id ?? '1' });
  const [verifyLoader, setVerifyLoader] = useState<boolean>(false);
  const [rejectLoader, setRejectLoader] = useState<boolean>(false);
  const [closePopup, setClosePopup] = useState<boolean>(false);

  const joinDate = moment(data?.data?.createdAt).format('DD MMM YYYY');
  const { addNotification } = useNotificationStore();

  const handleVerification = async () => {
    try {
      setVerifyLoader(true);
      let payload = {
        value: 'accepted',
        driverId: data?.data?.id ?? '',
      };
      await verifyDocuments(payload);
      setVerifyLoader(false);
      addNotification({
        type: 'success',
        title: 'Document verified successfuly',
      });
      refetch();
      setClosePopup(true);
      setTimeout(() => setClosePopup(false));
    } finally {
      setVerifyLoader(false);
    }
  };

  const handleRejection = async (values: ResponseValues) => {
    try {
      setRejectLoader(true);
      let payload = {
        value: 'rejected',
        reason: values?.reason,
        driverId: data?.data?.id ?? '',
      };
      await verifyDocuments(payload);
      setRejectLoader(false);
      addNotification({
        type: 'success',
        title: 'Document rejected successfuly',
      });
      refetch();
      close();
    } finally {
      setRejectLoader(false);
    }
  };

  const navs: {
    [key: string]: string;
  } = {
    accepted: '#008000',
    rejected: '#FF0000',
    pending: '#FFBF00',
    requested: '#72bcd4',
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  function convertDate(date: string) {
    if (/^\d{4}\-\d{2}\-\d{2}$/.test(date)) {
      return date;
    }
    if (/^\d{4}\/\d{2}\/\d{2}$/.test(date)) {
      return date;
    }
    if (/^\d{2}\-\d{2}\-\d{4}$/.test(date)) {
      const newDate = date.split('-');
      const formatDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      return formatDate;
    }
    const newDate = date.split('/');
    const formatDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    return formatDate;
  }

  return (
    <>
      {isLoading || isFetching ? (
        <div className="d-flex justify-content-center align-items-center">
          <NewSpinner />
        </div>
      ) : (
        <ContentLayout title="View Driver Details">
          <div className="card shadow border-0 rounded add-user-form space-card mt-4">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-2">
                <img
                  src={data?.data?.image ? data?.data?.image : placeHolderProfile}
                  className="user-images"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeHolderProfile;
                  }}
                />
              </div>
              <div className="col-12 col-md-8 col-lg-10">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Driver Name </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.name}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Email </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.email}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Phone Number </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.phoneNumber}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Date of Birth</b>
                      </div>
                      <div className="driver-rghtt">
                        {data?.data?.dob
                          ? moment(convertDate(data?.data?.dob)).format('DD MMM YYYY')
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Driving License </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.documents?.drivingLicense}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>License Expiry Date </b>
                      </div>
                      <div className="driver-rghtt">
                        {data?.data?.documents?.dateOfExpiry
                          ? moment(convertDate(data?.data?.documents?.dateOfExpiry)).format(
                              'DD MMM YYYY'
                            )
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Since Joined </b>
                      </div>
                      <div className="driver-rghtt">{joinDate}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-5 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Status </b>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <span
                            className={
                              data?.data?.status === 'active' ? 'colored-circle' : 'red-circle'
                            }
                          />
                        </div>
                        <div className="driver-rghtt">{upperFirst(data?.data?.status)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Vehicle Plate No. </b>
                      </div>
                      <div
                        className="driver-rghtt"
                      >
                        {data?.data?.documents?.vehiclePlate}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Insurance Expiry </b>
                      </div>
                      <div className="driver-rghtt">{data?.data?.documents?.insuranceExpiry ?? 'N/A'}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-5">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Service Type</b>
                      </div>
                      <div className="driver-rghtt">
                      <ul className='p-0'>
                        {data?.data?.vehicleType?.map((vehicle) => (
                          <span key={vehicle}>
                         
                              <li>
                            {vehicle?.vehicleType} 
                            </li>       
                          </span>
                        ))}
                          </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Doc Status </b>
                      </div>
                      <div
                        className="driver-rghtt"
                        style={{ color: navs[data?.data?.docStatus ?? ''] }}
                      >
                        {upperFirst(data?.data?.docStatus)}
                      </div>
                    </div>
                  </div>
                  {data?.data.docStatus === 'rejected' && (
                    <div className="col-12 col-md-12 mb-4">
                      <div className="driver-flex">
                        <div className="driver-left">
                          <b>Reason</b>
                        </div>
                        <div className="driver-rghtt">{data?.data.rejectReason}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card shadow border-0 rounded add-user-form space-card mt-4">
            <h5 className="f-18 pb-4">Documents</h5>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">ID Proof</h5>
                  {data?.data?.documents?.images?.idProof ? (
                    <img
                      src={data?.data?.documents?.images?.idProof}
                      className="doc-images"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = proof;
                      }}
                    />
                  ) : (
                    <img src={proof} className="doc-images" />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">License Front</h5>
                  {data?.data?.documents?.images?.licenseFront ? (
                    <img
                      src={data?.data?.documents?.images?.licenseFront}
                      className="doc-images"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = license;
                      }}
                    />
                  ) : (
                    <img src={proof} className="doc-images" />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">License Back</h5>
                  {data?.data?.documents?.images?.licenseBack ? (
                    <img
                      src={data?.data?.documents?.images?.licenseBack}
                      className="doc-images"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = license;
                      }}
                    />
                  ) : (
                    <img src={proof} className="doc-images" />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">Vehicle Insurance</h5>
                  {data?.data?.documents?.images?.insurance ? (
                    data?.data?.documents?.images?.insurance?.split('.').at(-1) === 'pdf' ? (
                      <object
                        data={data?.data?.documents?.images?.insurance}
                        type="application/pdf"
                        width="100%"
                        height="205px"
                      ></object>
                    ) : (
                      <img
                        src={data?.data?.documents?.images?.insurance}
                        className="doc-images"
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = proof;
                        }}
                      />
                    )
                  ) : (
                    <img src={proof} className="doc-images" />
                  )}
                </div>
                {data?.data?.documents?.images?.insurance?.split('.').at(-1) === 'pdf' && (
                  <Button
                    className="mt-2"
                    onClick={() => window.open(data?.data?.documents?.images?.insurance, '_blank')}
                  >
                    View File
                  </Button>
                )}
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">Soat</h5>
                  {data?.data?.documents?.images?.soat ? (
                    data?.data?.documents?.images?.soat?.split('.').at(-1) === 'pdf' ? (
                      <object
                        data={data?.data?.documents?.images?.soat}
                        type="application/pdf"
                        width="100%"
                        height="205px"
                      ></object>
                    ) : (
                      <img
                        src={data?.data?.documents?.images?.soat}
                        className="doc-images"
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = proof;
                        }}
                      />
                    )
                  ) : (
                    <img src={proof} className="doc-images" />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">Vehicle</h5>
                  {data?.data?.documents?.images?.vehicle ? (
                    <img
                      src={data?.data?.documents?.images?.vehicle}
                      className="doc-images"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = proof;
                      }}
                    />
                  ) : (
                    <img
                      src={proof}
                      className="doc-images"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = proof;
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <div className="id-proof text-center border p-3">
                  <h5 className="f-18 pb-2">Vehicle Plate Image</h5>
                  {data?.data?.documents?.images?.vehiclePlateImage ? (
                    <img
                      src={data?.data?.documents?.images?.vehiclePlateImage}
                      className="doc-images"
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = proof;
                      }}
                    />
                  ) : (
                    <img
                      src={proof}
                      className="doc-images"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = proof;
                      }}
                    />
                  )}
                </div>
              </div>
              {data?.data?.docStatus === 'requested' && (
                <div className="doc-verification">
                  <ConfirmationDialog
                    triggerButton={
                      <Button
                        tabIndex={-1}
                        className="icon me-2"
                        onClick={() => setClosePopup(true)}
                      >
                        Verify Document
                      </Button>
                    }
                    confirmButton={
                      <Button
                        variant="primary"
                        className="expanded-btn"
                        isLoading={verifyLoader}
                        onClick={handleVerification}
                      >
                        Confirm
                      </Button>
                    }
                    title="Document Verification"
                    icon="danger"
                    body="Are you sure you want to do verify document ?"
                    isDone={closePopup}
                  />

                  <Button
                    tabIndex={-1}
                    className="icon me-2"
                    variant="danger"
                    onClick={() => open()}
                  >
                    Reject Document
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ContentLayout>
      )}
      <div className="helpModals">
        <Modal
          open={isOpen}
          onClose={() => close()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please type reason here
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="col-md-12 col-sm-12">
                <Form<ResponseValues, typeof schema> onSubmit={handleRejection} schema={schema}>
                  {({ register, formState }) => (
                    <>
                      <div className="row">
                        <>
                          <div className="col-12">
                            <TextAreaField
                              error={formState.errors['reason']}
                              registration={register('reason')}
                            />
                          </div>

                          <div className="mt-3 d-flex justify-content-center align-items-center">
                            <Button type="submit" className="mt-2" isLoading={rejectLoader}>
                              Submit
                            </Button>
                          </div>
                        </>
                      </div>
                    </>
                  )}
                </Form>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ViewDriver;
