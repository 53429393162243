import { Button, ConfirmationDialog, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import exportIcon from '@/assets/export_icon.png';
import { useVehicles } from '../../api/vehicle/getVehicleList';
import { usePagination } from '@/hooks/usePagination';
import { useEffect, useState } from 'react';
import { VehicleData } from './vehicleType';
import { upperFirst } from 'lodash';
import trash from '@/assets/delete.png';
import edit from '@/assets/edit_jorge.png';
import { useNavigate } from 'react-router-dom';
import { useNotificationStore } from '@/stores/notifications';
import { deleteVehicle } from '../../api/vehicle/deleteVehicle';
import { vehicleCsv } from '../../api/vehicle/vehicleCsv';
import placeHolderProfile from '@/assets/placeholder.jpg';
import Switch from '@mui/material/Switch';
import { changeVehicleStatus } from '../../api/vehicle/changeVehicleStatus';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';
import { Menu, MenuItem } from '@mui/material';

const Vehicle = () => {
  const { page, changePage } = usePagination();
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState<string>('');
  const [statusKey, setStatusKey] = useState<string>('');
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    data: vehicle,
    isLoading,
    isFetching,
    refetch,
  } = useVehicles({
    page: page,
    statusKey: statusKey,
    searchKey: searchKey,
  });

  const options = [
    {
      label: 'Status',
      value: '',
    },
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ];

  const handleSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleStatus = (value: string) => {
    setStatusKey(value);
  };

  const handleDelete = async (id: string) => {
    setDeleteLoader(true);
    await deleteVehicle(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'User deleted successfuly',
    });
    setDeleteLoader(false);
  };

  const handleExport = async (type: string) => {
    try {
      setExportLoader(true);
      const fileName = await vehicleCsv(
       { statusKey: statusKey,
        searchKey: searchKey,
        type}
      );
      const url = process.env.REACT_APP_API_URL;
      window.open(`${url}/${fileName?.data}`);
      setExportLoader(false);
    } finally {
      setExportLoader(false);
    }
  };

  const handleStatusChange = async (id: string) => {
    await changeVehicleStatus(id);
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'Status changed successfuly',
    });
    setDeleteLoader(false);
  };

  useEffect(() => {
    refetch();
  }, [searchKey, statusKey]);

  useEffect(() => {
    refetch();
  }, [page, deleteLoader]);

  return (
    <>
      <ContentLayout title="">
        <div className="container my-4">
          <div className="row">
            <div className="col-md-4">
              <h3 className="font-bold">Vehicle Type</h3>
            </div>
            <div className="col-md-8 d-flex justify-content-end align-items-center">
              <Button type="button" variant="primary" onClick={() => navigate('/add-vehicle')}>
                Add Vehicle Type
              </Button>

              <Button
                className="px-4 ms-2"
                variant="outline"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                isLoading={exportLoader}
                startIcon={<img src={exportIcon} width="20" />}
                onClick={handleClick}
              >
                Export
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleExport('all');
                    handleClose();
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExport('filters');
                    handleClose();
                  }}
                >
                  with Filters
                </MenuItem>
              </Menu>

              <Button
                type="button"
                className="px-4 ms-4"
                variant="outline"
                onClick={() => navigate('/sortable')}
              >
                Vehicle Order
              </Button>
            </div>
          </div>

          <div className="row my-4 search-bar">
            <div className="col-md-6 d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                style={{ paddingRight: '0.75rem' }}
                placeholder="Search"
                value={searchKey}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <select
                name="location"
                className="form-select"
                value={statusKey}
                onChange={(e) => handleStatus(e.target.value)}
              >
                {options.map(({ label, value }) => (
                  <option key={label?.toString()} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {isLoading || isFetching ? (
            <div className="d-flex justify-content-center align-items-center">
              <NewSpinner />
            </div>
          ) : (
            <div className="bg-white shadow rounded mt-3">
              <div className="row mt-3 mb-2">
                <Table<VehicleData>
                  page={page}
                  changePage={changePage}
                  totalPages={vehicle?.totalPages ?? 1}
                  total={vehicle?.totalCount ?? 1}
                  data={vehicle?.data ?? []}
                  columns={[
                    {
                      title: 'Type',
                      field: 'vehicleType',
                      Cell({ entry: { vehicleType, image } }) {
                        return (
                          <>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={image ? image : placeHolderProfile}
                                style={{
                                  width: '35px',
                                  height: '35px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  position: 'relative',
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = placeHolderProfile;
                                }}
                                alt=""
                              />
                              <span
                                style={{
                                  position: 'relative',
                                }}
                              >
                                {upperFirst(vehicleType)}
                              </span>
                            </div>
                          </>
                        );
                      },
                    },
                    {
                      title: 'Extra Charge',
                      field: 'extraCharge',
                      Cell({ entry: { extraCharge } }) {
                        return <span>{extraCharge ?? 0} sole</span>;
                      },
                    },
                    {
                      title: 'Minimum Price',
                      field: 'minimumPrice',
                      Cell({ entry: { minimumPrice } }) {
                        return <span>{minimumPrice ?? 0} sole</span>;
                      },
                    },
                    { title: 'Person Capacity', field: 'personCapacity' },
                    {
                      title: 'Charges Per Min',
                      field: 'chargesPerMin',
                      Cell({ entry: { chargesPerMin } }) {
                        return <span>{chargesPerMin ?? 0} sole</span>;
                      },
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      Cell({ entry: { status, _id } }) {
                        return (
                          <>
                            <div className="d-flex align-items-center justify-content-center">
                              <Switch
                                color="primary"
                                defaultChecked={status === 'active'}
                                onChange={() => handleStatusChange(_id)}
                              />
                            </div>
                          </>
                        );
                      },
                    },
                    {
                      title: 'Action',
                      field: 'id',
                      Cell({ entry: { _id } }) {
                        return (
                          <>
                            <Button
                              tabIndex={-1}
                              variant="outline"
                              className="icon me-2"
                              title="Edit"
                              onClick={() => navigate(`/edit-vehicle/${_id}`)}
                            >
                              <img src={edit} className="h-5" alt="" />
                            </Button>
                            <ConfirmationDialog
                              triggerButton={
                                <Button
                                  tabIndex={-1}
                                  className="icon me-2"
                                  variant="outline"
                                  title="Delete"
                                >
                                  <img src={trash} className="h-5" alt="" />
                                </Button>
                              }
                              confirmButton={
                                <Button
                                  variant="primary"
                                  className="expanded-btn"
                                  onClick={() => handleDelete(_id)}
                                  isLoading={deleteLoader}
                                >
                                  Delete
                                </Button>
                              }
                              title="Delete Vehicle"
                              icon="danger"
                              body="Are you sure you want to delete this vehicle type ?"
                            />
                          </>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </ContentLayout>
    </>
  );
};

export default Vehicle;
