import { Form, InputField } from '@/components/Form';
import { ContentLayout } from '@/components/Layout'
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Spinner } from '@/components/Elements';
import { postContent } from '../../api/contentManagement/postContent';
import { useNotificationStore } from '@/stores/notifications';
import { useContent } from '../../api/contentManagement/getContent';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

const schema = z.object({
    title: z.string().min(1, 'Please enter title'),
    text: z.string().optional(),
});

export type FormValues = {
    title: string;
    text: string;
};
const stateFromHTML = require('draft-js-import-html').stateFromHTML;
const ContentPage = () => {
    const { id } = useParams();
    const { addNotification } = useNotificationStore();
    const [searchParams] = useSearchParams();
    const userType = searchParams.get('userType');
    const name = searchParams.get('name');

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const [html, setHtml] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState(false);

    const { data, isLoading, isFetching } = useContent({
        userType: userType ?? '',
        contentType: name ?? ''
    });


    const convetContentToHtml = () => {
        const currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
        setHtml(currentContentAsHTML)
    }

    const handleChange = (state: any) => {
        setEditorState(state);
        convetContentToHtml();
    }

    useEffect(() => {
        if (data?.data) {
            setReload(true);
            setTimeout(() => setReload(false), 0);
        }
    }, [data?.data]);

    function htmlDecode(input: string): string {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent ?? '';
    }
    useEffect(() => {
        if (data?.data?.text) {
            setHtml(data?.data?.text);
            const contentState = stateFromHTML(htmlDecode(data?.data?.text));
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
        else {
            setEditorState(() => EditorState.createEmpty())
        }

    }, [data?.data?.text]);

    return (
        <>
            {isLoading || isFetching ?
                <div className='d-flex justify-content-center align-items-center'>
                    <NewSpinner />
                </div> :
                <ContentLayout title={"Settings"}>
                    <div className="card shadow border-0 rounded add-user-form mt-4 settings-pages">
                        <h5 className='font-bold'>
                            {id === "1" || id === "4" ? "Terms & Conditions" :
                                (id === "2" || id === "5" ? "Privacy Policy" : "Licenses")}
                        </h5>

                        <div>
                            <Form<FormValues, typeof schema>
                                schema={schema}
                                onSubmit={async (values) => {
                                    const payload = {
                                        text: html ?? '',
                                        title: values?.title
                                    }
                                    if (userType && name) {
                                        setLoading(true);
                                        await postContent(userType, name, payload);
                                        addNotification({
                                            type: 'success',
                                            title: 'Content updated successfuly'
                                        })
                                        setLoading(false)
                                    }
                                }}
                                options={{
                                    defaultValues: {
                                        ...data?.data,
                                    },
                                }}
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="row mt-4">
                                            <div className="col-md-12 mb-4">
                                                <InputField
                                                    error={formState.errors['title']}
                                                    registration={register('title')}
                                                    type="text"
                                                    label="Title"
                                                    placeholder="Title"
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                    <label>Text</label>

                                                    <div className="border">
                                                        <Editor editorState={editorState} onEditorStateChange={handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-md-12 d-flex justify-content-end">
                                                    <Button
                                                        type="submit"
                                                        className='px-4'
                                                        isLoading={loading}
                                                    >
                                                        SAVE
                                                    </Button>

                                                </div>
                                            </div>
                                       
                                    </>
                                )}
                            </Form>
                        </div>
                    </div>

                </ContentLayout >
            }
        </>
    )
}

export default ContentPage;