import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { User } from '../../auth/types/index';

export type UpdateProfileDTO = {
  data: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
  };
  id: string;
};

export const updateProfile = ({ data, id }: UpdateProfileDTO): Promise<User> => {
  return axios.patch(`/users/${id}`, data);
};

type UseUpdateProfileOptions = {
  config?: MutationConfig<typeof updateProfile>;
};

export const useUpdateProfile = ({ config }: UseUpdateProfileOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updateProfile) => {
      await queryClient.cancelQueries('auth-user');

      const previousProfile = queryClient.getQueryData<User>('auth-user');

      const { data: newData } = updateProfile;

      const combinedData = {
        ...newData,
        id: previousProfile?.id,
        role: previousProfile?.role,
        isEmailVerified: previousProfile?.isEmailVerified,
      };

      queryClient.setQueryData('auth-user', combinedData);

      return { previousProfile };
    },
    onError: (_, __, context: any) => {
      if (context?.previousProfile) {
        queryClient.setQueryData(
          ['auth-user', context.previousProfile.id],
          context.previousProfile
        );
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['auth-user', data.id]);
      addNotification({
        type: 'success',
        title: 'Profile Updated',
      });
    },
    ...config,
    mutationFn: updateProfile,
  });
};
