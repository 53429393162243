import { axios } from '../../../lib/axios';
import { useQuery } from 'react-query';
import { ExtractFnReturnType, QueryConfig } from '../../../lib/react-query';
import { UserDTO } from '../routes/users/userType';



export const getUsers = async (x?: string, status?: string, page?: number): Promise<UserDTO> => {
    if (!x && !status) {
        return await axios.get(`/users/getAllUsers?page=${page ?? 1}`);
    }
    return await axios.get(`users/getAllUsers?page=${page ?? 1}&x=${x}&status=${status}`)
}
type QueryFnType = typeof getUsers;

type Users = {
    config?: QueryConfig<QueryFnType>;
    x?: string;
    page: number;
    status?: string;
}

export const useFetchUsers = ({ config, x, page, status }: Users = { x: '', status: '', page: 1 }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['AllUsers'],
        queryFn: () => getUsers(x, status, page)
    })
}