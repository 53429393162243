import { axios } from "@/lib/axios";

type ImageUpload = {
    file: File
}

type UploadApiResponse = {
    url: string;
}

export const uploadImage = (data: ImageUpload): Promise<UploadApiResponse> => {
    const formData = new FormData();
    formData.append('file', data.file)
    return axios.post('/users/upload', formData)
}