import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';
import { RideDTO } from '../../routes/rides/rideType';

export const getPays = (page: number, search?: string, status?: string): Promise<RideDTO> => {
  if (search || status) {
    return axios.get(`/admin/all-paymentss?search=${search}&status=${status}`);
  }
  return axios.get(`/admin/all-paymentss?page=${page ?? 1}`);
};

type QueryFnType = typeof getPays;

type useRidesData = {
  config?: QueryConfig<QueryFnType>;
  page: number;
  search?: string;
  status?: string;
};

export const usePays = (
  { page, config, search, status }: useRidesData = { page: 1, search: '', status: '' }
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['Rides-pays'],
    queryFn: () => getPays(page, search, status),
  });
};
