import { axios } from "@/lib/axios";

type csvResponse = {
    status: Number,
    data: string
}

type CsvPayload = {
    page?: number,
    search?: string,
    status?: string,
    type?: string
}

export const paysCsv = ({page,search, status, type}: CsvPayload): Promise<csvResponse> => {
    return axios.get(`/admin/export-pays?page=${page ?? 1}&search=${search ?? ''}&status=${status ?? ''}&type=${type}`)
}