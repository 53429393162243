import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getUserRevenue = (): Promise<[]> => {
  return axios.get(`/admin/get-user-revenue?value=month`);
};

type QueryFnType = typeof getUserRevenue;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useUserRevenue = ({ config }: UseOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['user-revenue'],
    queryFn: () => getUserRevenue(),
  });
};