import { axios } from "@/lib/axios"
import { RideDTO } from "../../routes/rides/rideType"
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "react-query";

export const viewRide = (id: string): Promise<RideDTO> => {
    return axios.get(`/admin/view-ride/${id}`)
};

type QueryFnType = typeof viewRide;

type useRide = {
    config? : QueryConfig<QueryFnType>;
    id: string
};

export const useSingleRide  = ({config, id}: useRide = {id: ''}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['Ride'],
        queryFn: () => viewRide(id)
    })
}