import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export type DashboardData = {
  driverCount: number,
  rideCount: number,
  revenue: number,
  cancelled: number,
  vehicleCount: number,
  allUser: number
}

export type DashboardResponse = {
  status: number,
  data: DashboardData,
  message: string
}

export const getDashboard = (): Promise<DashboardResponse> => {
  return axios.get(`/admin/get-dashboard`);
};

type QueryFnType = typeof getDashboard;

type UseOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useDashboard = ({ config }: UseOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['user-dashboard'],
    queryFn: () => getDashboard(),
  });
};
