import React from 'react';
import moment from 'moment';
import { useUserData } from '../../api/getUserProfile';
import { useLocation, useParams } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { ContentLayout } from '@/components/Layout';
import placeHolderProfile from '@/assets/placeholder.jpg';

const ViewPayout = () => {
  const location = useLocation();
  console.log(location, 'location 10')
  const driver = location.state.payoutDetails;
  return (
    <>

      <ContentLayout title="">
        <div className="container my-4">
          <h3 className="font-bold">Payout Details</h3> 
          <div className="card shadow payout-details border-0 rounded add-user-form space-card mt-4">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-2">
                <img
                  src={driver?.image}
                  className="user-images"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeHolderProfile;
                  }}
                />
              </div>
              <div className="col-12 col-md-8 col-lg-10">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Driver Name </b>
                      </div>
                      <div className="driver-rghtt">{driver?.name}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Email </b>
                      </div>
                      <div className="driver-rghtt">{driver?.email}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Phone Number </b>
                      </div>
                      <div className="driver-rghtt">{driver?.phoneNumber}</div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-3 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Amount </b>
                      </div>
                      <div className="driver-rghtt">{driver?.amount} sol</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Payment ID </b>
                      </div>
                      <div className="driver-rghtt">{driver?.paymentId}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-4">
                    <div className="driver-flex">
                      <div className="driver-left">
                        <b>Payout Date</b>
                      </div>
                      <div className="driver-rghtt">{moment(driver?.payoutCreatedAt).format('DD MMM YYYY')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default ViewPayout;
