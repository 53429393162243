import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form'
import { ContentLayout } from '@/components/Layout'
import * as z from 'zod';
import { changePassword } from '../../api/changePassword';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';
import { useState } from 'react';


const schema = z
    .object({
        old_password: z.string().min(1, 'Please enter old password'),
        new_password: z
            .string()
            .min(1, 'Please enter new password')
            .min(8, 'Password must be atleast 8 characters')
            .regex(
                /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%]).*$/,
                'Password should be a combination of uppercase alphabets, numbers and special characters'
            ),
        confirmPassword: z.string().min(1, 'Please enter confirm password'),
    })
    .superRefine(({ confirmPassword, new_password }, ctx) => {
        if (confirmPassword !== new_password) {
            ctx.addIssue({
                path: ['confirmPassword'],
                code: 'custom',
                message: 'The passwords did not match',
            });
        }
    });

export type PasswordValues = {
    old_password: string;
    new_password: string;
    confirmPassword: string;
}

const Password = () => {
    const { user } = useAuth();
    const { addNotification } = useNotificationStore();

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            <ContentLayout title=''>
                <div className="container">
                    <h3 className="font-bold">Settings</h3>
                    <div className="bg-white rounded-lg mt-4 p-4">
                        <h6 className="font-bold">Password & Security</h6>
                        <div className="border border-grey-500 rounded-lg p-4 mt-8">
                            <Form<PasswordValues, typeof schema>
                                schema={schema}
                                onSubmit={async (values) => {
                                    try {
                                        setLoading(true)
                                        if (user?.data?.id) {
                                            await changePassword(user?.data?.id, values);
                                            addNotification({
                                                type: 'success',
                                                title: 'Password changed successfuly'
                                            })
                                        };
                                        setLoading(false)
                                    } finally {
                                        setLoading(false)
                                    }

                                }}
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="row">
                                            <div className="col-4 md-4">
                                                <InputField
                                                    label='Old Password*'
                                                    type='password'
                                                    error={formState.errors['old_password']}
                                                    registration={register('old_password')}
                                                />
                                            </div>
                                            <div className="col-4 md-4">
                                                <InputField
                                                    type='password'
                                                    label='New Password*'
                                                    error={formState.errors['new_password']}
                                                    registration={register('new_password')}
                                                />
                                            </div>
                                            <div className="col-4 md-4">
                                                <InputField
                                                    type='password'
                                                    label='Confirm Password*'
                                                    error={formState.errors['confirmPassword']}
                                                    registration={register('confirmPassword')}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-100 d-flex justify-content-end' style={{ paddingBottom: '120px' }}>
                                            <Button
                                                type='submit'
                                                variant='primary'
                                                isLoading={loading}
                                                style={{ padding: '5px 60px', marginRight: '20px', marginTop: '10px' }}
                                            >
                                                Change Password
                                            </Button>
                                        </div>

                                    </>
                                )}

                            </Form>
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </>
    )
}

export default Password