import { Button, Table } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout'
import React, { useEffect, useState } from 'react'
import {  HelpQuery, useHelpQueries } from '../../api/helpandSupport/getHelpQueries';
import { usePagination } from '@/hooks/usePagination';
import moment from 'moment';
import { upperFirst } from 'lodash';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material'
import { Form, TextAreaField } from '@/components/Form';
import * as z from 'zod';
import { useDisclosure } from '@/hooks/useDisclosure';
import { getSingleQuery } from '../../api/helpandSupport/getSingleQuery';
import { postQueryResponse } from '../../api/helpandSupport/postQueryResponse';
import { useNotificationStore } from '@/stores/notifications';
import './help.css';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

const schema = z.object({
    response: z.string().min(1, 'Please enter your response')
});


export type ResponseValues = {
    response: string;
}

const HelpSupport = () => {
    const [search, setSearch] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [clearFilter, setClearFilter] = useState<boolean>(false);
    const [singleQueryData, setSingleData] = useState<HelpQuery>();
    const [loading, setLoading] = useState<boolean>(false);

    const { page, changePage } = usePagination();
    const { open, close, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    const { data, isLoading, isFetching, refetch } = useHelpQueries({
        page: page,
        searchKey: search,
        statusKey: status,
        date: date
    })

    const options = [
        {
            label: 'Status',
            value: ''
        },
        {
            label: 'Pending',
            value: 'pending'
        },
        {
            label: 'Resolve',
            value: 'resolve'
        }
    ];

    const style = {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleSearchClick = () => {
        setClearFilter(false);
        refetch();
    };

    const handleClearFilter = () => {
        setSearch('');
        setStatus('');
        setDate('');
        setClearFilter(true);
    };

    const handleAction = async (id: string) => {
        if (id) {
            const singleData = await getSingleQuery(id);
            setSingleData(singleData?.data)
        }
        open();
    };

    const handleSubmit = async (values: ResponseValues) => {
        if (singleQueryData?._id) {
            try {
                setLoading(true);
                await postQueryResponse(singleQueryData?._id, values);
                addNotification({
                    type: 'success',
                    title: 'Response submitted successfuly'
                })
                setLoading(false);
                close();
                refetch();
            } catch (error) {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        refetch();
    }, [page, clearFilter])

    return (
        <>
            <ContentLayout title=''>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className='font-bold'>Help & Support</h3>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-3">
                            <input
                                type='text'
                                className='form-control'
                                style={{ paddingRight: '0.75rem' }}
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2">
                            <select
                                name="location"
                                className='form-select'
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                {options.map(({ label, value }) => (
                                    <option key={label?.toString()} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <input
                                type="date"
                                className='form-control'
                                placeholder='date'
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2" style={{ textAlign: "right" }}>
                            <Button
                                className='px-4'
                                onClick={handleSearchClick}
                            >
                                Search
                            </Button>
                        </div>
                        {/* <div className="col-md-2">
                            <Button
                                onClick={handleClearFilter}
                            >
                                All Queries
                            </Button>
                        </div> */}
                    </div>
                    {isLoading || isFetching ?
                    <div className='d-flex justify-content-center'>
                        <NewSpinner/>
                    </div>
                      :
                        <div className="bg-white rounded my-4">

                            <div className='my-4'>
                                <Table<HelpQuery>
                                    page={page}
                                    changePage={changePage}
                                    data={data?.data ?? []}
                                    total={data?.totalCount ?? 1}
                                    totalPages={data?.totalPages ?? 1}
                                    columns={[
                                        { title: 'User Name', field: 'name' },
                                        {
                                            title: 'Service',
                                            field: 'service',
                                            Cell({ entry: { service } }) {
                                                return <span>{upperFirst(service)}</span>
                                            }
                                        },
                                        {
                                            title: 'Role',
                                            field: 'role',
                                            Cell({ entry: { role } }) {
                                                return <span>{upperFirst(role)}</span>
                                            }
                                        },
                                        {
                                            title: 'Date',
                                            field: 'createdAt',
                                            Cell({ entry: { createdAt } }) {
                                                const newDate = moment(createdAt).format('DD MMM YYYY')
                                                return <span>{newDate}</span>
                                            }
                                        },
                                        {
                                            title: 'Queries',
                                            field: 'query',
                                            Cell({ entry: { query } }) {
                                                return <span>{`${query.slice(0, 50)}${query.length > 50 ? '..............' : ''}`}</span>
                                            }
                                        },
                                        {
                                            title: 'Action',
                                            field: 'status',
                                            Cell({ entry: { status, _id } }) {
                                                return (
                                                    <button
                                                        onClick={() => handleAction(_id)}
                                                    >
                                                        <span style={status === 'resolve' ? { color: "green" } : { color: "red" }}>{upperFirst(status)}</span>
                                                    </button>
                                                )

                                            }
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    }
                </div>
            </ContentLayout >

            <div className="helpModals">
                <Modal
                    open={isOpen}
                    onClose={() => close()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Query
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="col-md-12 col-sm-12">
                                <Form<ResponseValues, typeof schema>
                                    onSubmit={handleSubmit}
                                    schema={schema}
                                    options={{
                                        defaultValues: {
                                            response: singleQueryData?.response ? singleQueryData?.response : ''
                                        }
                                    }}
                                >
                                    {({ register, formState }) => (
                                        <>
                                            <div className="row">
                                                <div className="row popupRes">
                                                    <p>
                                                        <span>Name : </span>
                                                        {singleQueryData?.name}{' '}
                                                    </p>
                                                    <p>
                                                        <span>Email : </span>
                                                        {singleQueryData?.email}{' '}
                                                    </p>
                                                    <p>
                                                        <span>Phone : </span>
                                                        {singleQueryData?.phoneNumber}{' '}
                                                    </p>
                                                    <p className="row quryCont">
                                                        <div className="col-md-2 column-2">Query:</div>
                                                        <div className="col-md-10">{singleQueryData?.query}</div>
                                                    </p>
                                                    {singleQueryData?.status === 'resolve' &&
                                                        <p className="row quryCont">
                                                            <div className="col-md-3 column-3">Response:</div>
                                                            <div className="col-md-9">{singleQueryData?.response}</div>
                                                        </p>
                                                    }
                                                </div>
                                                {singleQueryData?.status === 'pending' && (
                                                    <>
                                                        <div className="col-12">
                                                            <TextAreaField
                                                                error={formState.errors['response']}
                                                                registration={register('response')}
                                                            />
                                                        </div>

                                                        <div className="mt-3 d-flex justify-content-center align-items-center">
                                                            <Button type="submit" className="mt-2" isLoading={loading}>
                                                                Resolve
                                                            </Button>
                                                        </div>
                                                    </>
                                                )
                                                }
                                            </div>
                                        </>
                                    )}
                                </Form>
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </div>

        </>
    )
}

export default HelpSupport