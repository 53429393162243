import { axios } from "@/lib/axios";

type Verify = {
    value: string,
    driverId: string,
    reason?: string
}

export const verifyDocuments = (payload: Verify): Promise<void> => {
    return axios.post('/admin/verify-documents',payload)
}