import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField, InputPhone } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import '../routes/auth.css';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';

const schema = z.object({
  first_name: z.string().min(1, 'Please enter first name'),
  last_name: z.string().min(1, 'Please enter last name'),
  legal_company_name: z.string().min(1, 'Please enter legal company name'),
  email: z.string().min(1, 'Please enter work email address'),
  phone_number: z.custom(isValidMobile, isValidMobileMsg),
});

type RegisterValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const { register, isRegistering } = useAuth();

  return (
    <div className="mx-3">
      <h5 className="text-center">Sign In</h5>

      <Form<RegisterValues, typeof schema>
        onSubmit={async (values) => {
          await register(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <div className="d-flex gap-3">
              <InputField
                floating
                type="text"
                label="First Name"
                error={formState.errors['first_name']}
                registration={register('first_name')}
              />
              <InputField
                floating
                type="text"
                label="Last Name"
                error={formState.errors['last_name']}
                registration={register('last_name')}
              />
            </div>

            <InputField
              floating
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div className="d-flex gap-3">
              <div className="w-50">
                <InputField
                  floating
                  type="password"
                  label="Password"
                  error={formState.errors['password']}
                  registration={register('password')}
                />
              </div>
              <div className="w-50">
                <InputPhone
                  floating
                  error={formState.errors['phone_number']}
                  registration={register('phone_number')}
                />
              </div>
            </div>
            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full">
                Get Started
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
