import React from 'react';
import ReactApexChart from 'react-apexcharts';

export class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [this.props.userData.userCount, this.props.userData.driverCount],

      options: {
        colors: ['#474a46', '#6CF102'],
        chart: {
          height: 300,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: ['Driver ', 'User'],
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          verticalAlign: 'middle',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Arial, sans-serif',
        },
        title: {
          text: '',
          floating: false,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444',
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userData !== this.props.userData) {
      this.setState({
        series: [this.props.userData.userCount, this.props.userData.driverCount],
      });
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height={330}
        />
      </div>
    );
  }
}
