import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

export type ContentValues = {
    _id: string,
    text: string,
    title: string,
    userType: string,
    contentType: string,
}


export const getContent = async (userType: string, contentType: string): Promise<AxiosResponse<ContentValues>> => {
    return await axios.get(`/admin/get-content?userType=${userType}&contentType=${contentType}`);
}
type QueryFnType = typeof getContent;

type Content = {
    config?: QueryConfig<QueryFnType>;
    userType: string;
    contentType: string
}

export const useContent = ({ config, userType, contentType }: Content = { userType: '', contentType: '' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['AllUsers'],
        queryFn: () => getContent(userType, contentType)
    })
}