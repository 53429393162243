import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ContentLayout } from '@/components/Layout';
import { getAllVehicles } from '../../api/vehicle/getVehicleList';
import { updateOrder } from '../../api/vehicle/updateOrder';
import { useNotificationStore } from '@/stores/notifications';

type SortableItemProps = {
  id: string;
  index: number;
};

const SortableItem: React.FC<SortableItemProps> = ({ id, children, index }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-id={id}
          style={{
            backgroundColor: snapshot.isDragging ? '#d3ffd3' : 'white',
            border: snapshot.isDragging ? '2px solid #4CAF50' : '1px solid #ddd',
            boxShadow: snapshot.isDragging ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
            fontSize: '18px',
            padding: '15px',
            borderRadius: '8px',
            ...provided.draggableProps.style,
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

const Sortable: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const { addNotification } = useNotificationStore();
  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllVehicles();
      setItems(res.data ?? []);
    };
    fetchData();
  }, []);

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);

    try {
      await updateOrder({ updatedOrder: reorderedItems });
      addNotification({
        type: 'success',
        title: 'Vehicle order updated Successfuly'
    })
    } catch (error) {
      console.error('Error updating order on backend:', error);
    }
  };

  return (
    <ContentLayout title="Vehicle Order">
      <div className="row">
        <div className="col-md-6">
          <h6 className="font-bold">(Drag to update)</h6>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable className="mb-3" droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <SortableItem key={item._id} id={item._id} index={index}>
                    <img
                      src={item.image}
                      alt="Vehicle Image"
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                    {item.vehicleType}
                  </SortableItem>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </ContentLayout>
  );
};

export default Sortable;
