import { ArchiveIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import './table.css';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  page: number;
  total: number;
  totalPages: number;
  dashboard?: boolean;
  changePage: (page: number) => void;
};

export const Table = <Entry extends { id?: string; _id?: string; sr?: number }>({
  data,
  columns,
  page,
  total,
  totalPages,
  dashboard,
  changePage,
}: TableProps<Entry>) => {
  const [indexedEntries, setIndexedEntries] = React.useState<Entry[]>([]);
  const [pageNo, setPageNo] = React.useState(1);
  const pageSize = 10;
  const [entries, setEntries] = React.useState<Entry[]>([]);
  const [paginationArray, setPaginationArray] = React.useState([1, 2, 3, 4, 5]);

 
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, pageNo - Math.floor(pageSize / 2));
    const endPage = Math.min(totalPages, startPage + pageSize - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  React.useEffect(() => {
    let start: number;
    if (page === 1) {
      start = 1;
    } else {
      start = pageSize * page - pageSize + 1;
    }
    const indexed = data.map((i) => {
      i.sr = start++;
      return i;
    });
    setPageNo(page);
    setIndexedEntries(indexed);
    setEntries(indexed.slice(0, pageSize));
  }, [data, total]);
  React.useEffect(() => {
    const numOfPage = Math.ceil(data.length / pageSize);

    let i = page;
    const arr = [];
    if (numOfPage > 1) {
      for (i = 1; i <= numOfPage; i++) {
        if (i > page - 3 && i < page + 3) {
          arr.push(i);
        }
      }
      setPaginationArray(arr);
    } else {
      setPaginationArray([1]);
    }
  }, [data, page, total]);

  // React.useEffect(() => {
  //   const numOfPage = Math.ceil(data.length / pageSize);

  //   const arr = [];

  //   if (numOfPage > 1) {
  //     for (let i = 1; i <= numOfPage; i++) {
  //       if (i > page - 3 && i < page + 3) {
  //         arr.push(i);
  //       }
  //     }
  //     setPaginationArray(arr);
  //   } else {
  //     setPaginationArray([1]);
  //   }

  //   const start = (page - 1) * pageSize;
  //   const end = page * pageSize;

  //   setEntries(indexedEntries.slice(start, end));
  // }, [data, page]);

  // const goBack = () => {
  //   let start, end;
  //   if (page > 1) {
  //     if (page === 2) {
  //       start = 0;
  //       end = pageSize;
  //     } else {
  //       start = (page - 1) * pageSize;
  //       end = page * pageSize;
  //     }
  //     setEntries(indexedEntries.slice(start, end));
  //     setPage(page - 1);
  //   }
  // };
  // const goBack = () => {
  //   if (page > 1) {
  //     changePage(page - 1);
  //     setEntries(indexedEntries.slice(page - 2, page - 1));
  //   }
  // };
  const goBack = () => {
    if (page > 1) {
      changePage(page - 1);
      setPageNo(page - 1);
      const startIndex = (page - 2) * pageSize;
      const endIndex = (page - 1) * pageSize;
      setEntries(indexedEntries.slice(startIndex, endIndex));
    }
  };
  const goForward = () => {
    const end = pageSize * pageNo;

    if (total && total >= 10) {
      changePage(page + 1);
      setPageNo(page + 1);
    }
  };

  const goToPage = (p: number) => {
    changePage(p);
    setPageNo(p);
    setEntries(indexedEntries.slice((p - 1) * pageSize, p * pageSize));
  };

  // const goToPage = (p: number) => {
  //   changePage(p.toString());
  //   setEntries(indexedEntries.slice(page));
  // };
  // const goForward = () => {
  //   const start = page * pageSize;
  //   const end = (page + 1) * pageSize;
  //   if (data.length > start) {
  //     setEntries(indexedEntries.slice(start, end));
  //     setPage(page + 1);
  //   }
  // };

  // const goToPage = (p: number) => {
  //   const start = (p - 1) * pageSize;
  //   const end = p * pageSize;
  //   if (data.length > start) {
  //     setEntries(indexedEntries.slice(start, end));
  //     setPage(p);
  //   }
  // };

  const getPaginationString = () => {
    let start, end;
    if (page === 1) {
      start = 1;
      end = pageSize;
    } else {
      start = pageSize * page - pageSize + 1;
      end = pageSize * page;
    }
    if (end > data.length) {
      end = data.length;
    }


    // return `Showing ${start} to ${end} of ${data.length} entries`;
  };

  // const getPaginationString = () => {
  //   let start, end;
  //   if (page === 1) {
  //     start = page;
  //     end = page * pageSize;
  //   } else {
  //     start = (page - 1) * pageSize;
  //     end = page * pageSize;
  //   }
  //   if (end > data.length) {
  //     end = data.length;
  //   }
  //   return `Showing ${start} to ${end} of ${data.length} entries`;
  // };

  if (!data?.length) {
    return (
      <div className="flex flex-col items-center justify-center text-gray-500 bg-white h-80">
        <ArchiveIcon className="w-16 h-16" />
        <h4>No Entries Found</h4>
      </div>
    );
  }
  if (dashboard) {
    return (
      <div className="flex flex-col">
        <div className="table-top">
          <div className="main-table w-100 inline-block min-w-full  align-middle">
            <div className="table-view">
              <table className="min-w-full divide-y divide-gray-200">
                {/* Render table headers */}
                <thead className="table-head">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-xs font-medium tracking-wider text-left text-gray-500"
                    >
                      Sr. No.
                    </th>
                    {columns.map((column, index) => (
                      <th
                        key={column.title + index}
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-600"
                      >
                        {column.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* Render first 5 entries */}
                <tbody className="common-table-body">
                  {indexedEntries.slice(0, 5).map((entry, entryIndex) => (
                    <tr
                      key={entry?._id || entryIndex}
                      data-table-id={entry?._id || entryIndex}
                      className="animate__animated animate__fadeInRight"
                    >
                      <td className="px-3 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                        {entry?.sr ?? 1}
                      </td>
                      {columns.map(({ Cell, field, title }, columnIndex) => (
                        <td
                          key={title + columnIndex}
                          className="px-3 py-2 text-sm font-medium text-gray-900 whitespace-nowrap"
                        >
                          {Cell ? <Cell entry={entry} /> : entry[field]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      <div className="table-top">
        <div className="main-table w-100 inline-block min-w-full  align-middle">
          <div className="table-view">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="table-head">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 text-xs font-medium tracking-wider text-left text-gray-500"
                  >
                    Sr. No.
                  </th>
                  {columns.map((column, index) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-600"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="common-table-body">
                {indexedEntries.map((entry, entryIndex) => (
                  <tr
                    key={entry?._id || entryIndex}
                    data-table-id={entry?._id || entryIndex}
                    className="animate__animated animate__fadeInRight"
                  >
                    <td className="px-3 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {entry?.sr ?? 1}
                    </td>
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className="px-3 py-2 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        {Cell ? <Cell entry={entry} /> : entry[field]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mt-3 ms-4">{getPaginationString()}</p>
          <div className="d-flex justify-content-end my-3 me-3 pe-2">
            <div
              className="page me-2"
              role="button"
              tabIndex={-1}
              onKeyDown={goBack}
              onClick={goBack}
            >
              <span>
                <i className="fa-solid fa-chevron-left" />
              </span>
            </div>
            {generatePageNumbers().map((i) => (
              <div key={i}>
                <div
                  role="button"
                  tabIndex={i}
                  onKeyDown={() => goToPage(i)}
                  onClick={() => goToPage(i)}
                  className={i === pageNo ? 'page number me-2 active' : 'page number me-2'}
                >
                  <span>{i}</span>
                </div>
              </div>
            ))}
            {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
              <div key={i}>
                {(data.length >= i * pageSize || i === 1 ) && (
                  <div
                    role="button"
                    tabIndex={pageNo}
                    defaultValue={pageNo}
                    onKeyDown={() => goToPage(i)}
                    onClick={() => goToPage(i)}
                    className={clsx('page number me-2 active', i === page && 'active')}
                  >
                    <span>{pageNo ? pageNo : 1}</span>
                  </div>
                 )}
              </div>
            ))} */}
            <div
              role="button"
              tabIndex={pageNo}
              onKeyDown={goForward}
              className="page me-2"
              onClick={goForward}
            >
              <span>
                <i className="fa-solid fa-chevron-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
