import { ContentLayout } from '@/components/Layout'
import edit from '@/assets/edit.png'
import profile from '@/assets/placeholder.jpg'
import { Button, Spinner } from '@/components/Elements'
import { Form, InputField, InputPhone } from '@/components/Form';
import * as z from 'zod';
import { useAuth } from '@/lib/auth';
import { useSelectFile } from '@/hooks/useSelectFile';
import { uploadImage } from '../../api/uploadImage';
import { useEffect, useState } from 'react';
import { updateAdmin } from '../../api/updateAdmin';
import { useNotificationStore } from '@/stores/notifications';
import { changeAdminImage } from '../../api/changeAdminImage';
import { isValidMobile, isValidMobileMsg } from '@/utils/validation';

const schema = z.object({
    name: z.string().min(1, 'Please enter first name'),
    // last_name: z.string().min(1, 'Please enter last name'),
    email: z.string().min(1, 'Please enter email'),
    phone_number: z.custom(isValidMobile, isValidMobileMsg),
})

export type PersonalDetails = {
    image?: string;
    name: string;
    // last_name: string;
    email: string;
    phone_number: string;
}

const PersonalInformation = () => {
    const { user, refetchUser } = useAuth();
    const { addNotification } = useNotificationStore();
    const { file: userFile, preview, handleChange } = useSelectFile();

    const [uploadLoader, setUploadLoader] = useState<boolean>(false);
    const [submitLoader, setSubmitLoader] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const handleUpload = async () => {
        let image = user?.data?.image ?? '';
        setUploadLoader(true);
        if (userFile && user?.data.id) {
            const response = await changeAdminImage(user?.data.id, { file: userFile });
            if (response) {
                image = response?.url;
            }
            addNotification({
                type: 'success',
                title: 'Image updated successfuly'
            });
            setError(false);
            refetchUser();
        }
        if (image === '') {
            setError(true);
            setUploadLoader(false);
            return;
        }
        setUploadLoader(false);
    };




    const handleSubmit = async (values: PersonalDetails) => {
        try {
            setSubmitLoader(true);
            if (user?.data.id) {
                await updateAdmin(user?.data.id, { ...values });
                addNotification({
                    type: 'success',
                    title: 'Personal Information updated successfuly'
                })
            };
            setSubmitLoader(false);
        }
        finally {
            setSubmitLoader(false);
        }

        refetchUser();
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        },0);
    }, [user])


    return (
        <>
            {loading ? <Spinner className='mx-3' /> :
                <ContentLayout title=''>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h3 className="font-bold">Settings</h3>
                            </div>
                        </div>

                        <div className="bg-white rounded-lg mt-3 p-4">
                            <div className="p-4">
                                <h5 className='font-bold'>Personal Information</h5>
                            </div>
                            <div className="border border-grey-500 rounded-lg p-4 mx-8">
                                <div className="row px-4 d-flex flex-row align-items-center">
                                    <div className="col-6">
                                        <div className="d-flex flex-row align-items-center gap-3">
                                            <label htmlFor="profileImage">
                                                <div>
                                                    {preview ?
                                                        <img
                                                            src={preview}
                                                            style={
                                                                {
                                                                    objectFit: 'cover',
                                                                    height: '85px',
                                                                    width: '85px',
                                                                    borderRadius: '50%'
                                                                }}
                                                            alt="" />
                                                        : user?.data?.image ?
                                                            (
                                                                <img
                                                                    src={user?.data?.image}
                                                                    style={
                                                                        {
                                                                            objectFit: 'cover',
                                                                            height: '85px',
                                                                            width: '85px',
                                                                            borderRadius: '50%'
                                                                        }}
                                                                    alt=""
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = profile;
                                                                    }}
                                                                />
                                                            ) :
                                                            <img
                                                                src={profile}
                                                                style={
                                                                    {
                                                                        objectFit: 'cover',
                                                                        height: '85px',
                                                                        width: '85px',
                                                                        borderRadius: '50%'
                                                                    }}
                                                                alt="" />
                                                    }
                                                </div>
                                            </label>
                                            <input
                                                type="file"
                                                accept='image/*'
                                                id='profileImage'
                                                className='d-none'
                                                onChange={handleChange}
                                            />

                                            <div>
                                                <h6 className='font-bold'>Upload a New Photo</h6>
                                            </div>
                                        </div>
                                        {error && <p className='text-red-500 mt-2'>Image is Required/*</p>}
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <Button
                                            className='primary px-4'
                                            onClick={handleUpload}
                                            isLoading={uploadLoader}
                                        >
                                            Upload
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="border border-grey-500 rounded-lg p-4  mx-8 mt-8">
                                <h6 className='font-bold'>Details</h6>
                                <Form<PersonalDetails, typeof schema>
                                    schema={schema}
                                    onSubmit={handleSubmit}
                                    options={{
                                        defaultValues: {
                                            phone_number: user?.data?.phoneNumber ? user?.data?.phoneNumber.toString() : '',
                                            name: user?.data?.name ? user?.data?.name : '',
                                            email: user?.data?.email ? user?.data?.email : ''
                                        }
                                    }}
                                >
                                    {({ register, formState }) => (
                                        <>
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <InputField
                                                        type='text'
                                                        label='Name'
                                                        error={formState.errors['name']}
                                                        registration={register('name')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <InputField
                                                        type='text'
                                                        label='Email'
                                                        error={formState.errors['email']}
                                                        registration={register('email')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-4">

                                                <div className="col-md-6">
                                                    <InputPhone
                                                        label='Phone Number'
                                                        value={user?.data?.phoneNumber ? user?.data?.phoneNumber.toString() : ''}
                                                        error={formState.errors['phone_number']}
                                                        registration={register('phone_number')}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <Button
                                                    className='px-4'
                                                    type='submit'
                                                    isLoading={submitLoader}
                                                >
                                                    Update
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </Form>
                            </div>
                        </div>
                    </div>
                </ContentLayout >
            }
        </>
    )
}

export default PersonalInformation