import { useNavigate } from 'react-router-dom';

import { LoginLayout } from '../components/LoginLayout';
import { ForgotPassword } from '../components/ForgotPassword';

export const Forgot = () => {
  const navigate = useNavigate();

  return (
    <LoginLayout
      title="Login"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam, orci sit amet sollicitudin scelerisque."
    >
      <ForgotPassword onSuccess={() => navigate('/auth/login')} />
    </LoginLayout>
  );
};
