import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { /* DriverValues, */ DriversListResponse } from '../../routes/drivers/driverTypes';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';



export const getDrivers = async (x?: string, status?: string, page?: number): Promise<DriversListResponse> => {
    if (!x && !status) {
        return await axios.get(`/admin/get-drivers?page=${page ?? 1}`);
    }
    return await axios.get(`/admin/get-drivers?page=${page ?? 1}&x=${x}&status=${status}`)
}
type QueryFnType = typeof getDrivers;

type Drivers = {
    config?: QueryConfig<QueryFnType>;
    x?: string;
    page: number;
    status?: string;
}

export const useDrivers = ({ config, x, page, status }: Drivers = { x: '', status: '', page: 1 }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['AllDrivers'],
        queryFn: () => getDrivers(x, status, page)
    })
}