import { ContentLayout } from "@/components/Layout"
import { AddVehicle } from "./AddVehicle"
import { useVehicleInfo } from "../../api/vehicle/getVehicleInfo"
import { useParams } from "react-router-dom"
import { useEffect } from "react"

export const EditVehicle = () => {
    const {id} = useParams();
    const {data, refetch} = useVehicleInfo({id: id ?? '1'});

    useEffect(()=> {
        refetch();
    },[])
    return (
        <>
        <ContentLayout title="">
            <div>
                <AddVehicle vehicle= {data?.data}/>
            </div>
        </ContentLayout>
        </>
    )
}