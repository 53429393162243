import { axios } from "@/lib/axios";

type csvResponse = {
    status: Number,
    data: string
}

export const userCsv = ({ x, status, type }: { x: string, status: string, type: string }): Promise<csvResponse> => {
    let url = '/users/user-csv?';
  
    if (type) {
      url += `type=${type}&`;
    }
  
    if (x) {
      url += `x=${x}&`;
    }

    if (status) {
      url += `status=${status}`;
    }

    if (url.endsWith('&')) {
      url = url.slice(0, -1);
    }
    return axios.get(url);
  };
  