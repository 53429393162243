import { axios } from '@/lib/axios';

type csvResponse = {
  status: Number;
  data: string;
};

export const vehicleCsv = ({
  searchKey,
  statusKey,
  type,
}: {
  searchKey: string | null;
  statusKey: string | null;
  type: string | null;
}): Promise<csvResponse> => {
  let url = '/admin/vehicle-csv?';

  if (searchKey) {
    url += `searchKey=${searchKey}&`;
  }

  if (statusKey) {
    url += `statusKey=${statusKey}&`;
  }

  if (type) {
    url += `type=${type}`;
  }

  if (url.endsWith('&')) {
    url = url.slice(0, -1);
  }
  return axios.get(url);
};
