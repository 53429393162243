import { Button, Table } from '@/components/Elements'
import { ContentLayout } from '@/components/Layout'
import { contentTable } from './contentTable'
import { usePagination } from '@/hooks/usePagination';
import action from '@/assets/action.png'
import { useNavigate } from 'react-router-dom';

const ContentManagement = () => {
    const { page, changePage } = usePagination();
    const navigate = useNavigate();
    return (
        <>
            <ContentLayout title='Settings'>
                <div className="bg-white rounded-lg mt-3">
                    <div className="row p-4">
                        <div className="col-6 md-6">
                            <h5 className='bold'>Content Management</h5>
                        </div>
                        <div className="table mt-3">
                            <Table
                                page={page}
                                changePage={changePage}
                                total={6}
                                totalPages={1}
                                data={contentTable}
                                columns={[
                                    { title: "UserType", field: "userType" },
                                    { title: "Page Name", field: "pageName" },
                                    {
                                        title: "Action",
                                        field: "id",
                                        Cell({ entry: { id, userType, pageName } }) {
                                            const nameType = pageName.split(' ');
                                            return <Button
                                                tabIndex={-1}
                                                className="icon me-2"
                                                variant="outline"
                                                title="View"
                                                onClick={()=> navigate(`/edit-content/${id}?userType=${userType}&name=${nameType[0]}`)}
                                            >
                                                <img src={action} className='h-5' alt="" />
                                            </Button>
                                        }
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </ContentLayout>
        </>
    )
}

export default ContentManagement