import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { VehicleDTO } from '../../routes/vehicle/vehicleType';


export const getVehicleInfo = (id: string): Promise<VehicleDTO> => {
  return axios.get(`/admin/get-vehicle/${id}`);
};

type QueryFnType = typeof getVehicleInfo;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useVehicleInfo = ({ id, config }: UseOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-vehicle'],
    queryFn: () => getVehicleInfo(id),
  });
};
