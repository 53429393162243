import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TripChart = ({ data }) => {
  // Check if data is empty
  if (!data || !data.data || data.data.length === 0) {
    return <div>No data available</div>;
  }

  const options = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: data.data.map((date) => date.date),
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    colors: ['#6CF102', '#474a46'], 
  };

  const series = [
    {
      name: 'Completed',
      data: data.data.map((ride) => ride.completed),
    },
    {
      name: 'Cancelled',
      data: data.data.map((ride) => ride.canceled),
    },
  ];

  return (
    <div style={{ overflowX: 'auto' }}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default TripChart;
