import { useEffect, useState } from 'react';
import 'rsuite/dist/rsuite.min.css';
import Nav from 'rsuite/Nav';
import Sidenav from 'rsuite/Sidenav';
import menu from '@/assets/dash.png';
import user from '@/assets/user.png';
import rides from '@/assets/auto_icon.png';
import payment from '@/assets/payment_icon.png';
import report from '@/assets/report.png';
import help from '@/assets/help.png';
import faq from '@/assets/faq.png';
import driver from '@/assets/driver_icon.png';
import auto from '@/assets/auto.png';
import pay from '@/assets/pay.svg'
import { useLocation, useNavigate } from 'react-router-dom';

const Navs: {
  [key: string]: string;
} = {
  '': '1',
  personal: '2-1',
  'general-settings': '2-2',
  password: '2-3',
  content: '2-4',
  'edit-content': '2-4',
  drivers: '3',
  'add-drivers': '3',
  'view-driver': '3',
  'edit-driver': '3',
  users: '4',
  user: '4',
  'add-user': '4',
  'view-user': '4',
  rides: '5',
  'view-ride': '5',
  'help-support': '8',
  faq: '10',
  'add-faq': '10',
  'edit-faq': '10',
  'view-faq': '10',
  vehicle: '11',
  sortable: '11',
  'add-vehicle': '11',
  'edit-vehicle': '11',
  payments: '9',
  payouts: '12',
  'view-payout': '12',
  report: '6',
};

export const MenuNav = () => {
  const [activeKey, setActiveKey] = useState('1');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const pathname = location.pathname.split('/');
      if (pathname.length > 1) {
        const nowPath = pathname[1];
        setActiveKey(Navs[nowPath]);
      }
    }
  }, [location]);

  return (
    <div>
      <div className="sidebare">
        {/* <Toggle
          onChange={setExpand}
          checked={expand}
          checkedChildren="Open"
          unCheckedChildren="Collapse"
        />
        <hr /> */}
        <Sidenav className="sider tras">
          <Sidenav.Body>
            <Nav activeKey={activeKey} onSelect={setActiveKey}>
              <Nav.Item eventKey="1" onClick={() => navigate('.')}>
                <img src={menu} alt="" /> <span>Dashboard</span>
              </Nav.Item>
              <Nav.Item eventKey="3" onClick={() => navigate('/drivers')}>
                <img src={driver} alt="" /> <span>Drivers</span>
              </Nav.Item>
              <Nav.Item eventKey="4" onClick={() => navigate('/users')}>
                <img src={user} alt="" /> <span>Users</span>
              </Nav.Item>
              <Nav.Item eventKey="5" onClick={() => navigate('/rides')}>
                <img src={rides} alt="" /> <span>Rides</span>
              </Nav.Item>
              <Nav.Item eventKey="11" onClick={() => navigate('/vehicle')}>
                <img src={auto} alt="" /> <span>Vehicle Type</span>
              </Nav.Item>
              <Nav.Item eventKey="9" onClick={() => navigate('/payments')}>
                <img src={payment} alt="" /> <span>Payments</span>
              </Nav.Item>
              <Nav.Item eventKey="12" onClick={() => navigate('/payouts')}>
                <img src={pay} alt="" /> <span>Payouts</span>
              </Nav.Item>
              <Nav.Item eventKey="6" onClick={() => navigate('/report')}>
                <img src={report} alt="" /> <span>Report</span>
              </Nav.Item>
              <Nav.Item eventKey="10" onClick={() => navigate('/faq')}>
                <img src={faq} alt="" /> <span>FAQ</span>
              </Nav.Item>
              <Nav.Menu placement="rightStart" eventKey="2" title="Settings" className="user-icon">
                <Nav.Item eventKey="2-1" onClick={() => navigate('/personal')}>
                  Personal Information
                </Nav.Item>
                <Nav.Item eventKey="2-2" onClick={() => navigate('/general-settings')}>
                  General Settings
                </Nav.Item>
                <Nav.Item eventKey="2-3" onClick={() => navigate('/password')}>
                  Password & Security
                </Nav.Item>
                <Nav.Item eventKey="2-4" onClick={() => navigate('/content')}>
                  Content Management
                </Nav.Item>
              </Nav.Menu>
              <Nav.Item eventKey="8" onClick={() => navigate('/help-support')}>
                <img src={help} alt="" /> <span>Help & Support</span>
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
          <Sidenav />
        </Sidenav>
      </div>
    </div>
  );
};
