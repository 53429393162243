import { useState } from "react";

export const Pagination = ({
    page,
    total,
    changePage,
    totalPages
}: {
    page: number,
    total: number,
    changePage: (page: number) => void,
    totalPages: number
}) => {
    const [pageNo, setPageNo] = useState(1);
    const pageSize = 10;

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, pageNo - Math.floor(pageSize / 2));
        const endPage = Math.min(totalPages, startPage + pageSize - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const goBack = () => {
        if (page > 1) {
            changePage(page - 1);
            setPageNo(page - 1);
        }
    };

    const goForward = () => {
        if (total && total >= 10) {
            changePage(page + 1);
            setPageNo(page + 1);
        }
    };

    const goToPage = (p: number) => {
        changePage(p);
        setPageNo(p);
    };

    const getPaginationString = () => {
        let start, end;
        if (page === 1) {
            start = 1;
            end = pageSize;
        } else {
            start = pageSize * page - pageSize + 1;
            end = pageSize * page;
        }
    };


    return (
        <>
            <div className="d-flex justify-content-between">
                <p className="mt-3 ms-4">{getPaginationString()}</p>
                <div className="d-flex justify-content-end my-3 me-3 pe-2">
                    <div
                        className="page me-2"
                        role="button"
                        tabIndex={-1}
                        onKeyDown={goBack}
                        onClick={goBack}
                    >
                        <span>
                            <i className="fa-solid fa-chevron-left" />
                        </span>
                    </div>
                    {generatePageNumbers().map((i) => (
                        <div key={i}>
                            <div
                                role="button"
                                tabIndex={i}
                                onKeyDown={() => goToPage(i)}
                                onClick={() => goToPage(i)}
                                className={i === pageNo ? 'page number me-2 active' : 'page number me-2'}
                            >
                                <span>{i}</span>
                            </div>
                        </div>
                    ))}
                    <div
                        role="button"
                        tabIndex={pageNo}
                        onKeyDown={goForward}
                        className="page me-2"
                        onClick={goForward}
                    >
                        <span>
                            <i className="fa-solid fa-chevron-right" />
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}