import { ContentLayout } from '@/components/Layout';
import * as z from 'zod';
import plus from '@/assets/plus.png';
import { Form, InputField, InputPhone } from '@/components/Form';
import { Button, Spinner } from '@/components/Elements';
import { useSelectFile } from '@/hooks/useSelectFile';
import { useEffect, useState } from 'react';
import { addUser, editUser } from '../../api/addUser';
import { useNavigate } from 'react-router-dom';
import { useNotificationStore } from '@/stores/notifications';
import { addVehicle, editVehicle } from '../../api/vehicle/addVehicle';
import { uploadImage } from '../../api/uploadImage';
import placeholder from '@/assets/placeholder.jpg';
import { NewSpinner } from '@/components/Elements/Spinner/SpinnerCube';

const schema = z.object({
  vehicleType: z.string().min(1, 'Please enter vehicle type'),
  extraCharge: z
    .string()
    .min(1, 'Please enter extra charge amount')
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only'),
  personCapacity: z
    .string()
    .min(1, 'Please enter person capacity')
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .refine(
      (value) => {
        const numericValue = parseInt(value, 10);
        return numericValue >= 1 && numericValue <= 13;
      },
      {
        message: 'Please enter a number between 1 and 13',
      }
    ),
  minimumPrice: z
    .string()
    .min(1, 'Please enter minimum price')
    .regex(/^(?:[0-9]\d*|0)$/, 'Please enter valid numbers only')
    .refine(
      (value) => {
        const numericValue = parseInt(value, 10);
        return numericValue > 0;
      },
      {
        message: 'Minimum price must be greater than zero',
      }
    ),
  chargesPerMin: z
    .string()
    .min(1, 'Please enter per min charges')
    .regex(/^\d*\.?\d*$/, 'Please enter valid numbers only')
    .refine(
      (value) => {
        const numericValue = parseFloat(value);
        return !isNaN(numericValue) && numericValue > 0;
      },
      {
        message: 'Charges per minute must be a valid positive number',
      }
    ),
});

export type AddVehicle = {
  id?: string;
  _id?: string;
  vehicleType: string;
  extraCharge: string;
  personCapacity: string;
  minimumPrice?: string;
  chargesPerMin?: string;
  image?: string;
};

export const AddVehicle = ({ vehicle }: { vehicle?: AddVehicle }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const { handleChange, preview: filePreview, file: userFile } = useSelectFile();
  const { addNotification } = useNotificationStore();

  useEffect(() => {
    if (vehicle) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [vehicle]);
  useEffect(() => {
    if (userFile) {
      setImageError(false);
    }
  }, [userFile]);
  const handleSubmit = async (values: AddVehicle) => {
    try {
      setLoading(true);
      let image = vehicle?.image ?? '';
      if (userFile) {
        const response = await uploadImage({ file: userFile });
        image = response.url;
      } else {
        image = vehicle?.image ?? '';
      }
      if (image === '') {
        setImageError(true);
        return;
      }
      const payload = {
        image: image,
        vehicleType: values.vehicleType,
        extraCharge: values?.extraCharge,
        personCapacity: values?.personCapacity,
        minimumPrice: values?.minimumPrice,
        chargesPerMin: values?.chargesPerMin,
      };

      if (vehicle && vehicle._id) {
        await editVehicle(vehicle._id, payload);
        addNotification({
          type: 'success',
          title: 'Vehicle type updated Successfuly',
        });
      } else {
        await addVehicle(payload);
        addNotification({
          type: 'success',
          title: 'Vehicle type added Successfuly',
        });
      }
      setLoading(false);
      navigate('/vehicle');
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {reload && (
        <div className="d-flex justify-content-center align-items-center">
          <NewSpinner />
        </div>
      )}
      <ContentLayout title={vehicle?._id ? 'Edit Vehicle Type' : 'Add Vehicle'}>
        <div className="card shadow border-0 rounded add-user-form mt-4 ">
          <p className="f-20 semi-bold mb-1">Please enter the following details</p>
          <div className="my-4">
            {!reload && (
              <Form<AddVehicle, typeof schema>
                schema={schema}
                onSubmit={handleSubmit}
                options={{
                  defaultValues: {
                    vehicleType: vehicle?.vehicleType?.toString() ?? '',
                    extraCharge: vehicle?.extraCharge?.toString() ?? '0',
                    personCapacity: vehicle?.personCapacity?.toString() ?? '1',
                    minimumPrice: vehicle?.minimumPrice?.toString() ?? '0',
                    chargesPerMin: vehicle?.chargesPerMin?.toString() ?? '0',
                  },
                }}
              >
                {({ register, formState }) => (
                  <>
                    <label>Image</label>
                    <div>
                      <label htmlFor="userInput">
                        <div className="photo">
                          {filePreview ? (
                            <img src={filePreview} alt="" className="previewImage setImage" />
                          ) : vehicle?.image ? (
                            <img
                              src={vehicle?.image}
                              className="previewImage setImage"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeholder;
                              }}
                              alt=""
                            />
                          ) : (
                            <img src={plus} alt="" width="60" />
                          )}
                        </div>
                      </label>
                      <input
                        accept="image/*"
                        type="file"
                        id="userInput"
                        className="d-none"
                        onChange={handleChange}
                      />
                      {imageError && (
                        <p className="text-red-500 mt-1 font-semibold">Image is Required </p>
                      )}
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 col-md-6 mb-4">
                        <InputField
                          error={formState.errors['vehicleType']}
                          registration={register('vehicleType')}
                          type="text"
                          label="Vehicle Type"
                          placeholder="Vehicle Type"
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <InputField
                          error={formState.errors['extraCharge']}
                          registration={register('extraCharge')}
                          type="text"
                          label="Extra Charge(S/.)"
                          placeholder="Extra Charge"
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-12 col-md-6 mb-4">
                        <InputField
                          error={formState.errors['personCapacity']}
                          registration={register('personCapacity')}
                          type="text"
                          label="Person Capacity"
                          placeholder="Person Capacity"
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <InputField
                          error={formState.errors['minimumPrice']}
                          registration={register('minimumPrice')}
                          type="text"
                          label="Minimum Price(S/.)"
                          placeholder="Minimum Price"
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <InputField
                          error={formState.errors['chargesPerMin']}
                          registration={register('chargesPerMin')}
                          type="text"
                          label="Charges Per Min(S/.)"
                          placeholder="Charges Per Min"
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 text-center">
                        <Button isLoading={loading} type="submit" className="px-4">
                          Save
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Form>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
