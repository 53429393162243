export const contentTable = [
    {   
        "id": "1",
        "userType" : "User",
        "pageName" : "Terms & Conditions",
        "Status" : "Active"
    },
    {   
        "id": "2",
        "userType" : "User",
        "pageName" : "Privacy Policy",
        "Status" : "Active"
    },
    {   
        "id": "3",
        "userType" : "User",
        "pageName" : "Licenses",
        "Status" : "Active"
    },
    {   
        "id": "4",
        "userType" : "Driver",
        "pageName" : "Terms & Conditions",
        "Status" : "Active"
    },
    {   
        "id": "5",
        "userType" : "Driver",
        "pageName" : "Privacy Policy",
        "Status" : "Active"
    },
    {   
        "id": "6",
        "userType" : "Driver",
        "pageName" : "Licenses",
        "Status" : "Active"
    },
]