import { Button } from '@/components/Elements';
import { Form, InputField, TextAreaField } from '@/components/Form'
import { ContentLayout } from '@/components/Layout'
import { useNotificationStore } from '@/stores/notifications';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { addfaq } from '../../api/faq/addFaq';

const schema = z.object({
    question: z.string().min(1, "Please enter question"),
    answer: z.string().min(1, "Plese enter answer")
})


export type FaqData = {
    question: string;
    answer: string;
}


const AddFaq = () => {
    const navigate = useNavigate();
    const { addNotification } = useNotificationStore();
    return (
        <>
            <ContentLayout title='Add FAQ'>
                <div className="card shadow border-0 rounded mt-3" style={{ padding: "40px" }}>
                    <div>
                        <Form<FaqData, typeof schema>
                            onSubmit={async (values) => {

                                await addfaq(values)
                                addNotification({
                                    type: 'success',
                                    title: 'FAQ Added Successfuly',
                                });
                                navigate('/faq')
                            }}
                            schema={schema}

                        >
                            {({ register, formState }) => (
                                <>
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <InputField
                                                type="text"
                                                label="FAQ Question"
                                                error={formState.errors['question']}
                                                registration={register('question')}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <TextAreaField
                                                label="FAQ Answer"
                                                className="h-24"
                                                error={formState.errors['answer']}
                                                registration={register('answer')}
                                            />

                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <Button
                                                className='px-3'
                                                type="submit"
                                            >
                                                SAVE
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </ContentLayout>
        </>
    )
}

export default AddFaq