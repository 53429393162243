import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type User = {
  data: {
    role: string,
    status: string,
    isEmailVerified: boolean,
    isDriverVerified: boolean,
    isDeleted: boolean,
    isProfileCompleted: boolean,
    registerWithPhone: boolean,
    isOnline: boolean,
    distanceIn: string,
    clock24: boolean,
    name: string,
    email: string,
    phoneNumber:string,
    dob: string,
    image: string,
    createdAt: string,
    id: string
  }
}

export const getUserData = (id: string): Promise<User> => {
  return axios.get(`/admin/get-user/${id}`);
};

type QueryFnType = typeof getUserData;

type UseOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const useUserData = ({ id, config }: UseOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-user'],
    queryFn: () => getUserData(id),
  });
};
