import { Link } from 'react-router-dom';
import * as z from 'zod';

import google from '@/assets/google_icon.png';
import intuit from '@/assets/intuit.png';
import xero from '@/assets/xero.svg';
import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import '../routes/auth.css';

const schema = z.object({
  email: z.string().min(1, 'Email is required '),
  password: z.string().min(1, 'Password is required'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div className="login-form-main">
      <h2 className="f-28 bold pb-2">Sign In</h2>
      <p className="f-14 pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await login(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              blueLabel
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              blueLabel
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <div className='forgot-link d-flex justify-content-between pb-2'>
                <FormControlLabel required control={<Checkbox />} label="Remember Me" />
                <Link to="/auth/forgot" className="forget-link">
                  Forgot Password?
                </Link>
              </div>
             
              <div className="login-btn text-center mt-5">
                <Button
                  // startIcon={<i className="fa-solid fa-lock" />}
                  isLoading={isLoggingIn}
                  type="submit"
                  className="w-full  rounded-radius w-75 m-auto"
                >
                  Log In
                </Button>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
